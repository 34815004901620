import { Grid2, useTheme } from "@mui/material";
import React from "react";
import appRoutes from "../../AppRoutes";
import { RoleView } from "../common/SecureComponent";
import PageInfoTile from "./PageInfoTile";
import { useNavigate } from "react-router-dom";

function HomeTiles({}) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    (<Grid2 container spacing={4} sx={{ marginTop: theme.spacing(4) }}>
      {appRoutes.map((route, index) => {
        if (route.settings.homeTileItem) {
          return (
            (<RoleView roles={route.settings.roles} key={index}>
              <Grid2
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                  xl: 4
                }}>
                <PageInfoTile
                  title={route.settings.title}
                  description={route.settings.description}
                  icon={route.settings.icon}
                  onClick={() => {
                    navigate(route.path);
                  }}
                />
              </Grid2>
            </RoleView>)
          );
        }
      })}
    </Grid2>)
  );
}

export default HomeTiles;
