import React from "react";
import { Box, Typography, Grid2, useTheme } from "@mui/material";
import dayjs from "dayjs";
import ConnectorVertical from "../utilities/ConnectorVertical";

const PointDescription = ({ pointName, pointTime }) => {
  const theme = useTheme();
  const time = dayjs(pointTime);

  return (
    <Box padding={theme.spacing(1)}>
      <Typography>{pointName}</Typography>
      <Typography
        fontSize={12}
        paddingTop={theme.spacing(0.5)}
        color={theme.palette.primary.main}
      >
        {time.format("HH:mm")}
      </Typography>
      <Typography
        fontSize={12}
        paddingBottom={theme.spacing(1)}
        paddingTop={theme.spacing(0.5)}
        color={theme.palette.primary.main}
      >
        {time.format("DD/MM/YYYY")}
      </Typography>
    </Box>
  );
};

function JourneyLegDisplay({ leg }) {
  const theme = useTheme();

  return (
    (<Grid2 container>
      <Grid2 size={1}>
        <ConnectorVertical />
      </Grid2>
      <Grid2
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        size={11}
      >
        <PointDescription
          pointName={leg.departureAirport}
          pointTime={leg.departureTime}
        />
        <Box sx={{ padding: theme.spacing(1), textAlign: "right" }}>
          <Typography fontSize={14}>{leg.flightTime}</Typography>
          <Typography fontSize={12}>{leg.aircraftCategoryName}</Typography>
        </Box>
      </Grid2>
      <Grid2 size={1}>
        <ConnectorVertical isLast />
      </Grid2>
      <Grid2 size={11}>
        <PointDescription
          pointName={leg.arrivalAirport}
          pointTime={leg.arrivalTime}
        />
      </Grid2>
    </Grid2>)
  );
}

export default JourneyLegDisplay;
