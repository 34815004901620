import React from "react";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Typography, Box, CircularProgress, useTheme } from "@mui/material";


function CircularProgressWithIcon(props) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                width: "100%",
                marginTop: theme.spacing(35),
            }}
        >
            <CircularProgress />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" component="div">
                    <FlightTakeoffIcon color="primary" />
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithIcon;