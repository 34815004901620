import React from "react";
import dayjs from "dayjs";
import {
  Grid2,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import VpnLockIcon from "@mui/icons-material/VpnLock";

function ItinerarySectionInitial({
  register,
  control,
  passengers,
  getValues,
  errors,
  isLoading = false,
}) {
  const theme = useTheme();

  const style = {
    tdRight: {
      textAlign: "right",
      padding: "0.3em",
      width: "100%",
      color: isLoading
        ? theme.palette.inputBorder.main
        : errors?.leadPassenger
          ? theme.palette.error.main
          : theme.palette.primary.main,
    },
    tdGeneral: {
      padding: "0.3em",
      width: "100%",
      color: isLoading
        ? theme.palette.inputBorder.main
        : errors?.leadPassenger
          ? theme.palette.error.main
          : theme.palette.primary.main,
    },
  };

  const commonTextFieldProps = {
    variant: "outlined",
    fullWidth: true,
    readOnly: true,
    required: true,
    sx: { pointerEvents: "none" },
  };

  return (
    <Grid2 spacing={4} sx={{ paddingTop: theme.spacing(1) }} container>
      <Grid2
        size={{
          xs: 12,
          md: 8,
        }}
      >
        <Box>
          <Grid2 spacing={4} container>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                required
                disabled={isLoading}
                {...register("cardHours", {
                  required: "This is Required",
                  min: { value: 0.01, message: "This must be greater than 0" },
                  pattern: {
                    value: /^\d*\.?\d+$/,
                    message: "This must be a number (e.g. 1.25)",
                  },
                })}
                label="Card Hours"
                helperText={errors?.cardHours?.message}
                error={!!errors?.cardHours}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                value={dayjs(getValues("tripDate")).format("DD/MM/YYYY")}
                label="Trip Date"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                {...register("departureAirport")}
                label="Departure Airport"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                value={dayjs(getValues("departureTime")).format(
                  "HH:MM - DD/MM/YYYY"
                )}
                label="Departure Time"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                {...register("arrivalAirport")}
                label="Arrival Airport"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                value={dayjs(getValues("arrivalTime")).format(
                  "HH:MM - DD/MM/YYYY"
                )}
                label="Arrival Time"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
              }}
            >
              <TextField
                {...commonTextFieldProps}
                {...register("flightTime")}
                label="Flight Time"
                InputProps={{ endAdornment: <VpnLockIcon /> }}
              />
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          md: 4,
        }}
      >
        <Box
          sx={{
            border: "1px solid",
            borderColor: errors?.leadPassenger
              ? theme.palette.error.main
              : theme.palette.inputBorder.main,
            borderRadius: "4px",
            padding: theme.spacing(2),
          }}
        >
          <Controller
            name="leadPassenger"
            sx={{ width: "100%" }}
            control={control}
            rules={{ required: "This is Required" }}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={style.tdGeneral} variant="head">
                        <b>Name</b>
                      </TableCell>
                      <TableCell sx={style.tdRight}>
                        <b>Lead Passenger</b>
                      </TableCell>
                    </TableRow>
                    {passengers.length === 0 ? (
                      <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                          <b>No Passengers Found</b>
                        </TableCell>
                        <TableCell
                          sx={style.tdGeneral}
                          variant="head"
                        ></TableCell>
                      </TableRow>
                    ) : (
                      passengers.map((p, index) => (
                        <TableRow key={index} sx={{ width: "100%" }}>
                          <TableCell sx={style.tdGeneral} variant="head">
                            {p.passengerName}
                          </TableCell>
                          <TableCell sx={style.tdRight}>
                            <FormControlLabel
                              disabled={isLoading}
                              sx={{ marginLeft: "auto" }}
                              value={p.passengerId}
                              control={
                                <Radio
                                  sx={{
                                    color: errors?.leadPassenger
                                      ? theme.palette.error.main
                                      : undefined,
                                  }}
                                />
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </RadioGroup>
            )}
          />
        </Box>
        <FormHelperText
          sx={{ paddingLeft: theme.spacing(3) }}
          error={!!errors?.leadPassenger}
        >
          {errors?.leadPassenger?.message}
        </FormHelperText>
      </Grid2>
    </Grid2>
  );
}

export default ItinerarySectionInitial;
