import React from "react";
import { TableRow, TableCell, Box } from "@mui/material";

function MachDataTableRows({
  sortedRows,
  columns,
  onRowClick,
  renderCellContent,
  selected,
}) {
  return (
    <>
      {sortedRows.map((row, rowIndex) => (
        <TableRow
          key={rowIndex}
          hover={!!onRowClick}
          onClick={!!onRowClick ? () => onRowClick(row) : undefined}
          sx={{ cursor: !!onRowClick ? "pointer" : undefined }}
          selected={selected !== null && selected?.id === row?.id}
        >
          {columns.map((column, colIndex) => (
            <TableCell key={`${rowIndex}.${colIndex}`}>
              {renderCellContent(row, column)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}

export default MachDataTableRows;
