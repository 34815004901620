import React from "react";
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { renderCellContent } from "./helpers";

function MachDataTableCards({
  sortedRows,
  columns,
  titleField,
  subtitleField,
  onRowClick,
}) {
  return sortedRows.map((row, index) => (
    <Card
      key={index}
      variant="outlined"
      sx={{ marginBottom: 2, cursor: !!onRowClick ? "pointer" : undefined }}
      onClick={!!onRowClick ? () => onRowClick(row) : undefined}
    >
      <CardContent>
        <Typography variant="h6">
          {renderCellContent(row, { field: titleField })}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {renderCellContent(row, { field: subtitleField })}
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table size="small">
            <TableBody>
              {columns.map((column, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {column.headerName}
                  </TableCell>
                  <TableCell>{renderCellContent(row, column)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  ));
}

export default MachDataTableCards;
