import React from "react";
import { Button, useTheme, Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function MachDataTableToolbar({ handlePdfExport, toolbarButtons }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.main,
      }}
    >
      <Box>
        {toolbarButtons.map(
          (button, index) =>
            !button?.hide && (
              <Button
                key={index}
                size="small"
                startIcon={button?.icon ?? undefined}
                onClick={button.onClick}
                sx={{ marginRight: theme.spacing(4) }}
                color="secondary"
              >
                {button.label}
              </Button>
            )
        )}
      </Box>
      <Button
        onClick={handlePdfExport}
        size="small"
        color="secondary"
        endIcon={<FileDownloadIcon />}
      >
        Export
      </Button>
    </Box>
  );
}

export default MachDataTableToolbar;
