import React, { useEffect } from "react";
import Layout from "../common/Layout";
import CenterComponent from "../utilities/CenterContent";
import { useParams, useNavigate } from "react-router-dom";
import { Grid2, useTheme } from "@mui/material";
import FlightDisplayBanner from "./FlightDisplayBanner";
import FlightDetailsWidget from "./FlightDetailsWidget";
import ItineraryDetailsWidget from "./ItineraryDetailsWidget";
import PassengerDetailsWidget from "./PassengerDetailsWidget";
import { IsValidGuid } from "../../utils/tools";

function FlightManagementPage({}) {
  const { scheduleId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!scheduleId && !IsValidGuid(scheduleId)) {
      navigate("/notFound");
    }
  }, [scheduleId]);

  const theme = useTheme();

  return (
    (<Layout>
      <CenterComponent height="auto">
        <FlightDisplayBanner scheduleId={scheduleId} />
      </CenterComponent>
      <CenterComponent height="auto" widthSetting="large" sx={{ marginTop: 0 }}>
        <Grid2 spacing={6} sx={{ marginTop: theme.spacing(4) }} container>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 4
            }}>
            <FlightDetailsWidget scheduleId={scheduleId} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 4
            }}>
            <ItineraryDetailsWidget scheduleId={scheduleId} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 4
            }}>
            <PassengerDetailsWidget scheduleId={scheduleId} />
          </Grid2>
        </Grid2>
      </CenterComponent>
    </Layout>)
  );
}

export default FlightManagementPage;
