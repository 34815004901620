import React from "react";
import { Drawer, Box, useTheme, Button, Alert } from "@mui/material";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import { useQuery } from "@tanstack/react-query";
import { getWalletItineraryData } from "../../API/Itineraries";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import ViewItineraryTable from "./ViewItineraryTable";

function ViewWalletItineraryPanel({ open, handleClose, scheduleId }) {
  const theme = useTheme();

  const walletItineraryDataQuery = useQuery({
    queryKey: ["getWalletItineraryData", scheduleId],
    queryFn: () => getWalletItineraryData(scheduleId).promise,
  });

  const walletItineraryData = walletItineraryDataQuery?.data;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "500px" },
        },
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(5),
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(100vh - 50px)",
        }}
      >
        <PageTitle type="medium">View Wallet Itinerary</PageTitle>
        <PageTypography
          sx={{ color: theme.palette.primaryText.main, marginBottom: 0 }}
        >
          Current Wallet Itinerary Information.
        </PageTypography>

        <QueryHandlerContainer query={walletItineraryDataQuery}>
          <ViewItineraryTable itineraryData={walletItineraryData} />
          <Box
            sx={{
              justifyContent: "end",
              display: "flex",
              flexDirection: "column",
              marginTop: "auto",
            }}
          >
            <Alert
              severity="info"
              variant="outlined"
              color="secondary"
              sx={{ marginBottom: theme.spacing(2) }}
            >
              This shows the latest Final Itinerary in 'Completed' state.
              <br />
              If unavailable, it shows the latest Initial Itinerary in
              'Completed' state.
            </Alert>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </QueryHandlerContainer>
      </Box>
    </Drawer>
  );
}

export default ViewWalletItineraryPanel;
