import React, { useState } from "react";
import {
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Divider,
  useTheme,
  Button,
  Box,
} from "@mui/material";
import { getFlightManagementPassengers } from "../../API/FlightManagement";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PageSubTitle, PageTypography } from "../utilities/PageTitle";
import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from "@mui/icons-material/People";
import ClearIcon from "@mui/icons-material/Clear";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import { removeFlightPassengerAssociation } from "../../API/FlightManagement";
import AddPassengerSection from "./AddPassengerSection";
import { LoadingButton } from "@mui/lab";
import { JetCardTechSupportContact } from "../../constants";

function PassengerDetailsWidget({ scheduleId }) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [addPassengerSectionOpen, setAddPassengerSectionOpen] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [deleteLoadingPassengerId, setDeleteLoadingPassengerId] =
    useState(null);

  const flightManagementPassengersQuery = useQuery({
    queryKey: ["getFlightManagementPassengers", scheduleId],
    queryFn: () => getFlightManagementPassengers(scheduleId).promise,
  });

  const handleRemovePassenger = (_passenger) => {
    setDeleteLoadingPassengerId(_passenger.passengerId);
    const { promise, abort } = removeFlightPassengerAssociation(
      scheduleId,
      _passenger.passengerId
    );
    promise.then(() => {
      setApiError(false);
      setDeleteLoadingPassengerId(null);
      queryClient.invalidateQueries({
        queryKey: ["getFlightManagementPassengers", scheduleId],
      });
      queryClient.invalidateQueries({
        queryKey: ["getUpdatedScheduleDetails", scheduleId],
      });
    });
    promise.catch(() => {
      setApiError(true);
      setDeleteLoadingPassengerId(null);
    });
    return () => abort();
  };

  const flightManagementPassengers = flightManagementPassengersQuery?.data;

  const exsistingPassengerIds = flightManagementPassengers?.map(
    (pass) => pass.passengerId
  );

  return (
    <QueryHandlerContainer
      height="400px"
      width="100%"
      query={flightManagementPassengersQuery}
    >
      <Card
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor: theme.palette.backgroundContrast.secondary,
          border: "none",
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={<PeopleIcon sx={{ fontSize: "2em" }} />}
          title={
            <PageSubTitle
              variant="subtitle1"
              sx={{ marginTop: theme.spacing(1) }}
            >
              Passengers
            </PageSubTitle>
          }
        />
        <Divider />
        {flightManagementPassengers?.length > 0 ? (
          flightManagementPassengers.map((passenger, index) => (
            <Box key={index}>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PageSubTitle type="small" sx={{ margin: 0 }}>
                  {passenger.name}
                </PageSubTitle>
                <LoadingButton
                  loading={deleteLoadingPassengerId === passenger.passengerId}
                  disabled={deleteLoadingPassengerId !== null}
                  sx={{ padding: theme.spacing(1) }}
                  onClick={() => handleRemovePassenger(passenger)}
                >
                  <ClearIcon />
                </LoadingButton>
              </CardContent>
              <Divider />
            </Box>
          ))
        ) : (
          <CardContent>
            <PageTypography sx={{ marginTop: "0" }}>
              No Passengers Found.
            </PageTypography>
          </CardContent>
        )}
        {apiError ? (
          <Box>
            <CardContent>
              <PageTypography
                fontWeight="bold"
                sx={{ color: theme.palette.error.main }}
              >
                Error, Please try again.
              </PageTypography>
              <PageTypography sx={{ color: theme.palette.error.main }}>
                If this continues, contact our Support Team:{" "}
                {JetCardTechSupportContact}
              </PageTypography>
            </CardContent>
            <Divider />
          </Box>
        ) : (
          <></>
        )}
        {addPassengerSectionOpen ? (
          <AddPassengerSection
            open={addPassengerSectionOpen}
            setOpen={setAddPassengerSectionOpen}
            scheduleId={scheduleId}
            exsistingPassengerIds={exsistingPassengerIds}
          />
        ) : (
          <>
            <Divider />
            <CardActions sx={{ justifyContent: "end" }}>
              <Button
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => setAddPassengerSectionOpen(true)}
              >
                Add Passenger
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </QueryHandlerContainer>
  );
}

export default PassengerDetailsWidget;
