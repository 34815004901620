import React from "react";
import Layout from "../common/Layout"
import CenterComponent from "../utilities/CenterContent";
import PageTitle from "../utilities/PageTitle";
import HomeTiles from "./HomeTiles"
import BookingsOverviewBarChart from "./BookingsOverviewBarChart";
import { useTheme } from "@mui/material";

function HomePage() {

    const theme = useTheme();
    return (
        <Layout>
            <CenterComponent>
                <PageTitle sx={{ color: theme.palette.secondary.main }}>
                    Welcome
                </PageTitle>
                <BookingsOverviewBarChart />
                <HomeTiles />
            </CenterComponent>
        </Layout>
    );
}

export default HomePage;