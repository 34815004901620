import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ItinerarySectionInitial from "./ItinerarySectionInitial";
import { CardContent, CardActions, Box, useTheme } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  mapInitialItineraryDtoToFormValues,
  mapFormValuesToInitialItineraryDto,
  mapUpdatedItineraryDtoToFormValues,
} from "./ItineraryHelpers";
import { PageTypography } from "../utilities/PageTitle";
import { JetCardTechSupportContact } from "../../constants";
import {
  createInitialItinerary,
  editInitialItinerary,
  createAndCompleteInitialItinerary,
  editAndCompleteInitialItinerary,
  getUpdatedScheduleDetails,
} from "../../API/Itineraries";

function InitialItineraryModalContent({
  values,
  isNewItinerary,
  setCanClose,
  scheduleId,
  handleCloseWithRefresh,
}) {
  const theme = useTheme();
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveAndCompleteLoading, setSaveAndCompleteLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [passengers, setPassengers] = useState(values.passengers);
  const isLoading = saveLoading || saveAndCompleteLoading || refreshLoading;
  const defaultValues = { ...mapInitialItineraryDtoToFormValues(values) };
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    setCanClose(!isLoading);
  }, [isLoading]);

  const handleSave = (data) => {
    setSaveLoading(true);
    const itineraryApiCall = isNewItinerary
      ? createInitialItinerary
      : editInitialItinerary;
    const { promise, abort } = itineraryApiCall(
      mapFormValuesToInitialItineraryDto(scheduleId, data, passengers)
    );
    promise.then(() => {
      setApiError(false);
      setSaveLoading(false);
      handleCloseWithRefresh();
    });
    promise.catch(() => {
      setApiError(true);
      setSaveLoading(false);
    });
    return () => abort();
  };

  const handleSaveAndComplete = (data) => {
    setSaveAndCompleteLoading(true);
    const itineraryApiCall = isNewItinerary
      ? createAndCompleteInitialItinerary
      : editAndCompleteInitialItinerary;
    const { promise, abort } = itineraryApiCall(
      mapFormValuesToInitialItineraryDto(scheduleId, data, passengers)
    );
    promise.then(() => {
      setApiError(false);
      setSaveAndCompleteLoading(false);
      handleCloseWithRefresh();
    });
    promise.catch(() => {
      setApiError(true);
      setSaveAndCompleteLoading(false);
    });
    return () => abort();
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    const { promise, abort } = getUpdatedScheduleDetails(scheduleId);
    promise.then((data) => {
      const newFormValues = mapUpdatedItineraryDtoToFormValues(data);
      setPassengers(data.passengers);
      const oldFormValues = getValues();
      reset({ ...oldFormValues, ...newFormValues });
      setApiError(false);
      setRefreshLoading(false);
    });
    promise.catch(() => {
      setApiError(true);
      setRefreshLoading(false);
    });
    return () => abort();
  };

  return (
    <>
      <CardContent sx={{ width: "1000px" }}>
        <ItinerarySectionInitial
          control={control}
          register={register}
          passengers={passengers}
          getValues={getValues}
          errors={errors}
          isLoading={isLoading}
        />
        {apiError && (
          <Box sx={{ marginTop: theme.spacing(3) }}>
            <PageTypography
              fontWeight="bold"
              sx={{ color: theme.palette.error.main }}
            >
              Error, Please try again.
            </PageTypography>
            <PageTypography sx={{ color: theme.palette.error.main }}>
              If this continues, contact our Support Team:{" "}
              {JetCardTechSupportContact}
            </PageTypography>
          </Box>
        )}
      </CardContent>
      <CardActions>
        <LoadingButton
          color="secondary"
          variant="outlined"
          onClick={handleRefresh}
          sx={{ isNewItinerary }}
          endIcon={<PublicIcon />}
          loading={refreshLoading}
          disabled={isLoading}
          loadingPosition="end"
        >
          Refresh
        </LoadingButton>
        <LoadingButton
          color="secondary"
          variant="outlined"
          onClick={() => handleSave(getValues())}
          startIcon={<SaveIcon />}
          sx={{ marginLeft: "auto !important" }}
          disabled={isLoading}
          loading={saveLoading}
          loadingPosition="start"
        >
          Save
        </LoadingButton>
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={handleSubmit(handleSaveAndComplete)}
          endIcon={<DoneAllIcon />}
          loading={saveAndCompleteLoading}
          disabled={isLoading}
          loadingPosition="end"
        >
          Save & Complete
        </LoadingButton>
      </CardActions>
    </>
  );
}

export default InitialItineraryModalContent;
