import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAirportSearch } from "../../API/Estimates";

function AirportPicker({ onChange, value, disabled, label, sx }) {
  const [filter, setFilter] = useState("");
  const getAirportSearchQuery = useQuery({
    queryKey: ["getAirportSearch", filter],
    queryFn: () => getAirportSearch(filter).promise,
    enabled: filter.length >= 3,
  });

  const getAirportSearchQueryData = getAirportSearchQuery?.data;
  return (
    <Autocomplete
      options={getAirportSearchQueryData ?? []}
      sx={sx}
      onChange={onChange}
      value={value}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        if (newInputValue === value?.name) {
          setFilter("");
        } else {
          setFilter(newInputValue);
        }
      }}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      noOptionsText="Start typing..."
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          size="small"
        />
      )}
    />
  );
}

export default AirportPicker;
