import React, { useState } from "react";
import Layout from "../common/Layout";
import CenterComponent from "../utilities/CenterContent";
import UserTable from "./UserTable";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddUserPanel from "./AddUserPanel";
import { useUserContext } from "../common/SecureComponent";
import ManageUserPanel from "./ManageUserPanel";

function MachUserManagePage() {
  const user = useUserContext();
  const [openAddUserPanel, setOpenAddUserPanel] = useState(false);
  const [openManageUserPanel, setOpenManageUserPanel] = useState(false);
  const [selectedUserRow, setSelectedUserRow] = useState({});
  const showManageButton =
    !!selectedUserRow?.id && user?.id !== selectedUserRow?.email;

  return (
    <Layout>
      <CenterComponent>
        <AddUserPanel
          open={openAddUserPanel}
          setOpenAddUserPanel={setOpenAddUserPanel}
        />
        <ManageUserPanel
          open={openManageUserPanel}
          setOpenManageUserPanel={setOpenManageUserPanel}
          selectedUser={selectedUserRow}
        />
        <UserTable
          setSelectedUserRow={setSelectedUserRow}
          selectedUserRow={selectedUserRow}
          toolbarButtons={[
            {
              label: "Add User",
              icon: <PersonAddIcon />,
              onClick: () => {
                setOpenAddUserPanel(true);
              },
            },
            {
              label: "Manage User",
              icon: <ManageAccountsIcon />,
              onClick: () => {
                setOpenManageUserPanel(true);
              },
              hide: !showManageButton,
            },
          ]}
        />
      </CenterComponent>
    </Layout>
  );
}

export default MachUserManagePage;
