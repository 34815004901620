import React, { useState } from "react";
import { Drawer, Box, useTheme, Button, Tooltip } from "@mui/material";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import { useQuery } from "@tanstack/react-query";
import { JetCardTechSupportContact } from "../../constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getItineraryData, exportToPdf } from "../../API/Itineraries";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import ViewItineraryTable from "./ViewItineraryTable";
import { LoadingButton } from "@mui/lab";

function downloadPdf(blob) {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = "generated.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
}

function ViewItineraryPanel({ open, handleClose, itineraryId }) {
  const theme = useTheme();
  const [exportItineraryError, setExportItineraryError] = useState(false);
  const [exportPdfLoading, setExportPdfLoading] = useState(false);

  const itineraryDataQuery = useQuery({
    queryKey: ["getItineraryData", itineraryId],
    queryFn: () => getItineraryData(itineraryId).promise,
    enabled: !!itineraryId,
  });

  const handleExportPdf = () => {
    setExportPdfLoading(true);
    const { promise, abort } = exportToPdf(itineraryId);
    promise.then((data) => {
      setExportItineraryError(false);
      setExportPdfLoading(false);
      downloadPdf(data);
    });
    promise.catch(() => {
      setExportItineraryError(true);
      setExportPdfLoading(false);
    });
    return () => abort();
  };

  const itineraryData = itineraryDataQuery?.data;
  const canExport = itineraryData?.status === "Complete";

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "500px" },
        },
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(5),
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(100vh - 50px)",
        }}
      >
        <PageTitle type="medium">View Itinerary</PageTitle>
        <PageTypography
          sx={{ color: theme.palette.primaryText.main, marginBottom: 0 }}
        >
          Review Itinerary Details Below.
        </PageTypography>
        <QueryHandlerContainer query={itineraryDataQuery}>
          <ViewItineraryTable itineraryData={itineraryData} />
          {exportItineraryError && (
            <Box sx={{ marginTop: theme.spacing(3) }}>
              <PageTypography
                fontWeight="bold"
                sx={{ color: theme.palette.error.main }}
              >
                Error Exporting Itinerary, Please try again.
              </PageTypography>
              <PageTypography sx={{ color: theme.palette.error.main }}>
                If this continues, contact our Support Team:{" "}
                {JetCardTechSupportContact}
              </PageTypography>
            </Box>
          )}
          <Box
            sx={{
              justifyContent: "end",
              display: "flex",
              flexDirection: "column",
              marginTop: "auto",
            }}
          >
            <Tooltip
              title={
                canExport
                  ? ""
                  : "Export is not Enabled till the Itinerary is Complete"
              }
              followCursor
            >
              <span>
                <LoadingButton
                  onClick={handleExportPdf}
                  sx={{ marginBottom: theme.spacing(2) }}
                  variant="outlined"
                  endIcon={<PictureAsPdfIcon />}
                  disabled={!canExport}
                  loading={exportPdfLoading}
                  loadingPosition="end"
                  fullWidth
                >
                  Export To PDF
                </LoadingButton>
              </span>
            </Tooltip>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </QueryHandlerContainer>
      </Box>
    </Drawer>
  );
}

export default ViewItineraryPanel;
