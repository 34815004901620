import { secureJsonFetch, securePost } from "../utils/secureFetch";
import { JetCardApiUri } from "../constants";


function getFlightManagementSummary(_scheduleId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalFlightManagement/getFlightManagementSummary?ScheduleId=${_scheduleId}`);
}

function getFlightManagementDetails(_scheduleId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalFlightManagement/getFlightManagementDetails?ScheduleId=${_scheduleId}`);
}

function getFlightManagementItineraries(_scheduleId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalFlightManagement/getFlightManagementItineraries?ScheduleId=${_scheduleId}`);
}

function getFlightManagementPassengers(_scheduleId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalFlightManagement/getFlightManagementPassengers?ScheduleId=${_scheduleId}`);
}

function removeFlightPassengerAssociation(_scheduleId, _passengerId) {
    return securePost(JetCardApiUri + "api/v1.0/portalFlightManagement/removeFlightPassengerAssociation", {ScheduleId: _scheduleId, PassengerId: _passengerId});
}



function linkPassengerToFlight(_passengerId, _scheduleId) {
    var body = { passengerId: _passengerId, scheduleId: _scheduleId };
    return securePost(JetCardApiUri + "api/v1.0/portalFlightManagement/linkPassengerToFlight", body);
}

function createAndLinkPassengerToFlight(_firstName, _lastName, _scheduleId) {
    var body = { scheduleId: _scheduleId, firstName: _firstName, lastName: _lastName };
    return securePost(JetCardApiUri + "api/v1.0/portalFlightManagement/createAndLinkPassengerToFlight", body);
}

function getPassengersForCustomerSchedules(_scheduleId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalFlightManagement/getPassengersForCustomerSchedules?ScheduleId=${_scheduleId}`);
}

export {
    getFlightManagementSummary,
    getFlightManagementDetails,
    getFlightManagementItineraries,
    getFlightManagementPassengers,

    removeFlightPassengerAssociation,
    linkPassengerToFlight,
    createAndLinkPassengerToFlight,

    getPassengersForCustomerSchedules

};