import React from "react";
import {
  Menu,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

function MachDataTableFilterMenu({
  anchorEl,
  filterColumn,
  filters,
  searchTerm,
  handleFilterMenuClose,
  handleSelectAllChange,
  distinctValues,
  handleFilterChange,
  setSearchTerm,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleFilterMenuClose}
      sx={{ maxHeight: "800px" }}
      variant="menu"
    >
      <MenuItem dense>
        <TextField
          fullWidth
          size="small"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </MenuItem>
      <MenuItem dense>
        <FormControlLabel
          sx={{ width: "100%", marginRight: 0 }}
          control={
            <Checkbox
              checked={
                filters[filterColumn.field]?.length ===
                distinctValues(filterColumn.field).length
              }
              onChange={handleSelectAllChange}
            />
          }
          label="Select All"
        />
      </MenuItem>
      {distinctValues(filterColumn.field).map((value, index) => (
        <MenuItem key={index} dense>
          <FormControlLabel
            sx={{ width: "100%", marginRight: 0 }}
            control={
              <Checkbox
                checked={filters[filterColumn.field]?.includes(value) || false}
                onChange={(event) => handleFilterChange(event, value)}
              />
            }
            label={value}
          />
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MachDataTableFilterMenu;
