
//  This tool is designed to test if a value is in Guid format.
//  This was designed to validate url passed parameters.
const IsValidGuid = (value) => {
    var guidRegex = /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;
    return guidRegex.test(value);
}

const IsValidEmail = (value) => {
    var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(value);
}

export {
    IsValidGuid,
    IsValidEmail,
};