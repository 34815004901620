import React from "react";
import { Box, useTheme, Divider } from "@mui/material";
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';

function ConnectorVertical({ isLast = false }) {

    const theme = useTheme();

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Box sx={{ position: "relative", width: "20px", height: "100%" }}>
                <FiberManualRecordOutlinedIcon
                    sx={{
                        fontSize: 20,
                        color: theme.palette.secondary.main,
                        position: "absolute",
                        top: 10,
                        left: "50%",
                        transform: "translate(-50%, 0%) rotate(90deg)",
                        zIndex: 999,
                    }}
                />
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        width: "1px",
                        height: isLast ? 0 : "calc(100% - 10px)",
                        position: "absolute",
                        top: "25px",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                />
            </Box>
        </Box>
    );
};

export default ConnectorVertical;