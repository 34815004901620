export const ACCESS_LEVEL = {
  BROKER: "Broker",
  SUPER_USER: "Super User",
  ADMINISTRATOR: "Administrator",
};

export const ROLES = {
  SUPER_USER: 0,
  BROKER: 1,
  ADMINISTRATOR: 2,
};

export const ROLE_LIST = [
  { id: ROLES.SUPER_USER, name: ACCESS_LEVEL.SUPER_USER },
  { id: ROLES.BROKER, name: ACCESS_LEVEL.BROKER },
  { id: ROLES.ADMINISTRATOR, name: ACCESS_LEVEL.ADMINISTRATOR },
];

export const APP_ROLES = [
  { id: 100, name: "App User" },
  { id: 101, name: "Account Manager" },
];

export const SCREENS = {
  HOME: "/",
};

export const DISPLAY_MODES = {
  LIGHT: "light",
  DARK: "dark",
};

export const JetCardApiUri = process.env.REACT_APP_JETCARD_API_URI;
export const JetCardTechSupportContact = "jetcardtechsupport@airpartner.com";
