import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableHead,
  useMediaQuery,
  Box,
  useTheme,
  TableRow,
  TableCell,
} from "@mui/material";
import MachDataTableToolbar from "./MachDataTableToolbar";
import MachDataTableHeaders from "./MachDataTableHeaders";
import MachDataTableRows from "./MachDataTableRows";
import MachDataTableCards from "./MachDataTableCards";
import MachDataTableFilterMenu from "./MachDataTableFilterMenu";
import {
  renderCellContent,
  extractTextFromJsx,
  sortRows,
  distinctValues,
} from "./helpers";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function MachDataTable({
  rows,
  columns,
  titleField,
  subtitleField,
  onRowClick = null,
  defaultSortField = null,
  defaultSortDirection = "asc",
  showToolbar = true,
  tableName = "MachDataTable",
  selected = null,
  toolbarButtons = [],
  exportOrientation = "portrait",
}) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [sortConfig, setSortConfig] = useState({
    field: defaultSortField,
    direction: defaultSortDirection,
  });
  const [filters, setFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const tableRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (defaultSortField) {
      setSortConfig({
        field: defaultSortField,
        direction: defaultSortDirection,
      });
    }
  }, [defaultSortField, defaultSortDirection]);

  const handleSort = (field) => {
    setSortConfig((prevState) => {
      if (prevState.field === field) {
        return {
          field,
          direction: prevState.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          field,
          direction: "asc",
        };
      }
    });
  };

  const handleFilterButtonClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setFilterColumn(column);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
    setFilterColumn(null);
    setSearchTerm("");
  };

  const handleFilterChange = (event, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterColumn.field]: event.target.checked
        ? [...(prevFilters[filterColumn.field] || []), value]
        : prevFilters[filterColumn.field].filter((v) => v !== value),
    }));
  };

  const handleSelectAllChange = (event) => {
    const values = distinctValues(
      filterColumn.field,
      rows,
      columns,
      searchTerm
    );
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterColumn.field]: event.target.checked ? values : [],
    }));
  };

  const sortedRows = sortRows(rows, sortConfig, filters, columns);

  const handlePdfExport = () => {
    const doc = new jsPDF({
      orientation: exportOrientation,
    });

    const tableColumnHeaders = columns.map((column) => column.headerName);

    const tableRows = sortedRows.map((row) =>
      columns.map((column) => {
        const renderedContent = renderCellContent(row, column);

        if (column.renderCell) {
          const jsxContent = column.renderCell({ row });
          const textContent = extractTextFromJsx(jsxContent);
          return textContent;
        }

        if (typeof renderedContent === "object" && renderedContent !== null) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = renderedContent;
          return tempDiv.textContent || tempDiv.innerText || "";
        } else {
          return renderedContent;
        }
      })
    );

    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      headStyles: { fillColor: theme.palette.secondary.main },
    });

    doc.save(`${tableName}.pdf`);
  };

  return (
    <Box>
      {isSmallScreen ? (
        <>
          {showToolbar && (
            <MachDataTableToolbar
              handlePdfExport={handlePdfExport}
              toolbarButtons={toolbarButtons}
            />
          )}
          <MachDataTableCards
            sortedRows={sortedRows}
            columns={columns}
            titleField={titleField}
            subtitleField={subtitleField}
            onRowClick={onRowClick}
          />
        </>
      ) : (
        <Table
          ref={tableRef}
          size="small"
          sx={{ maxHeight: "100%" }}
          stickyHeader
        >
          <TableHead>
            {showToolbar && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    padding: 0,
                    backgroundColor: theme.palette.background.main,
                  }}
                >
                  <MachDataTableToolbar
                    handlePdfExport={handlePdfExport}
                    toolbarButtons={toolbarButtons}
                  />
                </TableCell>
              </TableRow>
            )}
            <MachDataTableHeaders
              columns={columns}
              sortConfig={sortConfig}
              handleSort={handleSort}
              handleFilterButtonClick={handleFilterButtonClick}
            />
          </TableHead>
          <TableBody>
            <MachDataTableRows
              sortedRows={sortedRows}
              columns={columns}
              onRowClick={onRowClick}
              renderCellContent={renderCellContent}
              selected={selected}
            />
          </TableBody>
        </Table>
      )}
      {filterColumn && (
        <MachDataTableFilterMenu
          anchorEl={anchorEl}
          filterColumn={filterColumn}
          filters={filters}
          searchTerm={searchTerm}
          handleFilterMenuClose={handleFilterMenuClose}
          handleSelectAllChange={handleSelectAllChange}
          distinctValues={(field) =>
            distinctValues(field, rows, columns, searchTerm)
          }
          handleFilterChange={handleFilterChange}
          setSearchTerm={setSearchTerm}
        />
      )}
    </Box>
  );
}

export default MachDataTable;
