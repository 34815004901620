import React from "react";
import Layout from "./Layout";
import CenterComponent from "../utilities/CenterContent";
import PageTitle, { PageTypography } from "../utilities/PageTitle";

function InvalidPage() {

    return (
        <Layout>
            <CenterComponent>
                <PageTitle>
                    Not Found
                </PageTitle>
                <PageTypography>The resource you have requested has not been found.</PageTypography>
                <PageTypography>Please check your URL and try again.</PageTypography>
            </CenterComponent>
        </Layout>
    );
}

export default InvalidPage;