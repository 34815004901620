import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  useTheme,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid2,
} from "@mui/material";
import { getFlightManagementDetails } from "../../API/FlightManagement";
import { useQuery } from "@tanstack/react-query";
import { PageSubTitle } from "../utilities/PageTitle";
import FlightIcon from "@mui/icons-material/Flight";
import QueryHandlerContainer from "./../utilities/QueryHandlerContainer";
import dayjs from "dayjs";

function FlightDetailsWidget({ scheduleId }) {
  const flightManagementDetailsQuery = useQuery({
    queryKey: ["getFlightManagementDetails", scheduleId],
    queryFn: () => getFlightManagementDetails(scheduleId).promise,
  });

  const theme = useTheme();

  const style = {
    tdRight: {
      textAlign: "right",
      padding: "0.3em",
    },
    tdGeneral: {
      padding: "0.3em",
    },
  };

  const flightManagementDetails = flightManagementDetailsQuery?.data;

  return (
    (<QueryHandlerContainer
      height="400px"
      width="100%"
      query={flightManagementDetailsQuery}
    >
      <Card
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor: theme.palette.backgroundContrast.secondary,
          border: "none",
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={<FlightIcon sx={{ fontSize: "2em" }} />}
          title={
            <PageSubTitle
              variant="subtitle1"
              sx={{ marginTop: theme.spacing(1) }}
            >
              Flight Details
            </PageSubTitle>
          }
        />
        <Divider />
        <Grid2 container sx={{ width: "100%" }}>
          <Grid2
            size={{
              xs: 12,
              md: 6,
              xl: 12
            }}>
            <CardContent>
              <PageSubTitle type="medium" sx={{ marginTop: 0 }}>
                Departure
              </PageSubTitle>
              <Table padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Airport</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.departureAirportName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>ICAO</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.departureAirportIcao}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Time/Date</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {dayjs(flightManagementDetails?.departureTime).format(
                        "HH:mm - DD/MM/YYYY"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 6,
              xl: 12
            }}>
            <Divider
              sx={{ display: { sm: "block", md: "none", xl: "block" } }}
            />
            <CardContent>
              <PageSubTitle type="medium" sx={{ marginTop: 0 }}>
                Arrival
              </PageSubTitle>
              <Table padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Airport</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.arrivalAirportName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>ICAO</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.arrivalAirportIcao}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Time/Date</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {dayjs(flightManagementDetails?.arrivaTime).format(
                        "HH:mm - DD/MM/YYYY"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
            <CardContent>
              <Table padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Duration</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.flightDuration}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Aircraft Type</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.aircraftCategory}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={style.tdGeneral} variant="head">
                      <b>Customer</b>
                    </TableCell>
                    <TableCell sx={style.tdRight}>
                      {flightManagementDetails?.customer}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Grid2>
        </Grid2>
      </Card>
    </QueryHandlerContainer>)
  );
}

export default FlightDetailsWidget;
