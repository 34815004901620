const lightPalette = {
  border: {
    main: "#E5EAF2",
  },
  background: {
    main: "#f1f3f4",
  },
  backgroundContrast: {
    main: "#d3d4d8",
    secondary: "#e3e4e8",
  },
  primary: {
    main: "#1f1f1f",
    contrastText: "#ffffff",
  },
  primaryText: {
    main: "#1f1f1f",
  },
  secondary: {
    main: "#B28A72",
    contrastText: "#f1f3f4",
  },
  inputBorder: {
    main: "#e0e0e0",
  },
};

const darkPalette = {
  border: {
    main: "#3B4A5933",
  },
  background: {
    main: "#1f1f1f",
  },
  backgroundContrast: {
    main: "#2d2f31",
    secondary: "#28292a",
  },
  primary: {
    main: "#E3E3E3",
    contrastText: "#000000",
  },
  primaryText: {
    main: "#E3E3E3",
  },
  secondary: {
    main: "#B28A72",
    contrastText: "#1f1f1f",
  },
  inputBorder: {
    main: "#515151",
  },
};

const commonPalette = {
  error: {
    main: "#FF4c4c",
  },
  danger: {
    main: "#FF4c4c",
  },
};

const lightComponents = {};

const darkComponents = {};

const getDesignTokens = (mode) => ({
  spacing: 5,
  typography: {
    fontFamily: [
      "Open Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...(mode === "light" ? lightComponents : darkComponents),
  },
  palette: {
    mode,
    ...(mode === "light" ? lightPalette : darkPalette),
    ...commonPalette,
  },
});

export default getDesignTokens;
