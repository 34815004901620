import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  Typography,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoleView } from "./SecureComponent";
import { SCREENS } from "../../constants";
import appRoutes, { routes } from "../../AppRoutes";
import AirPartnerIcon from "./Icons/AirPartnerIcon";
import { useAuth } from "react-oidc-context";
import LogoutIcon from "@mui/icons-material/Logout";
import { ColorModeContext } from "../../styles/JetCardPortalThemeProvider";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

function NavigationDrawer({
  routeTitle,
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  drawerWidth,
}) {
  const navigate = useNavigate();
  const auth = useAuth();
  const theme = useTheme();
  const navigateTo = (route) => {
    if (route.index) navigate(SCREENS.HOME);
    else if (route.path) navigate(route.path);
    else throw "Route path can't be undefined " + route.title;
    handleDrawerClose();
  };
  const currentMode = theme.palette.mode;
  const colorMode = React.useContext(ColorModeContext);

  const desktopDrawer = (
    <Box
      sx={{ width: "100%", height: "100%" }}
      role="presentation"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        disablePadding
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(3),
          }}
        >
          <ListItemIcon
            sx={{
              color: theme.palette.primaryText.main,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AirPartnerIcon
              sx={{ fontSize: 35, marginBottom: theme.spacing(1) }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "14px", fontWeight: 700 }}
            primary="Mach"
            sx={{ color: theme.palette.primaryText.main, textAlign: "center" }}
          />
        </Box>
        {appRoutes.map((route, index) => {
          const { element } = route;
          if (
            route.settings.menuItem === true ||
            route.settings.title === routeTitle
          )
            return (
              <RoleView key={index} roles={route.settings.roles}>
                <ListItem key={route.settings.title} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigateTo(route);
                    }}
                    selected={route.settings.title === routeTitle}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color:
                        route.settings.title === routeTitle
                          ? theme.palette.secondary.main
                          : theme.palette.primaryText.main,
                      ":hover": { color: theme.palette.secondary.main },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "inherit",
                      }}
                    >
                      {route.settings.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "12px",
                        fontWeight: 620,
                      }}
                      primary={route.settings.title}
                      sx={{ textAlign: "center" }}
                    />
                  </ListItemButton>
                </ListItem>
              </RoleView>
            );
        })}
        <ListItem sx={{ mt: "auto" }} disablePadding>
          <ListItemButton
            onClick={colorMode.toggleColorMode}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: theme.palette.primaryText.main,
              ":hover": { color: theme.palette.secondary.main },
            }}
          >
            <ListItemIcon
              sx={{
                color: "inherit",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {currentMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "12px", fontWeight: 620 }}
              primary="Display Mode"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              auth.signoutPopup();
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: theme.palette.primaryText.main,
              ":hover": { color: theme.palette.secondary.main },
            }}
          >
            <ListItemIcon
              sx={{
                color: "inherit",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "12px", fontWeight: 620 }}
              primary="Sign Out"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const smallDrawer = (
    <Box
      sx={{ width: "100%", height: "100%" }}
      role="presentation"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: 0,
        }}
      >
        {appRoutes.map((route, index) => {
          const { element, ...rest } = route;
          if (
            route.settings.menuItem === true ||
            route.settings.title === routeTitle
          )
            return (
              <RoleView key={index} roles={route.settings.roles}>
                <ListItem key={route.settings.title} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigateTo(route);
                    }}
                    selected={route.settings.title === routeTitle}
                    sx={{
                      color:
                        route.settings.title === routeTitle
                          ? theme.palette.secondary.main
                          : theme.palette.primaryText.main,
                    }}
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      {route.settings.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      primary={route.settings.title}
                    />
                  </ListItemButton>
                </ListItem>
              </RoleView>
            );
        })}

        <ListItem sx={{ mt: "auto" }}>
          <ListItemButton onClick={colorMode.toggleColorMode}>
            <ListItemIcon sx={{ color: theme.palette.primaryText.main }}>
              {currentMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px", fontWeight: "bold" }}
              primary="Display Mode"
              sx={{ color: theme.palette.primaryText.main }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              auth.signoutPopup();
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.primaryText.main }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px", fontWeight: "bold" }}
              primary="Sign Out"
              sx={{ color: theme.palette.primaryText.main }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        backgroundColor: theme.palette.backgroundContrast.main,
        margin: {
          xs: 0,
          sm: `${theme.spacing(3)} 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
        },
        borderRadius: theme.spacing(3),
      }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { xs: "block", sm: "none" }, width: "400px" }}
      >
        <Box sx={{ display: "inline-flex", padding: "16px 48px 16px 24px" }}>
          <AirPartnerIcon
            fontSize="large"
            sx={{
              paddingRight: theme.spacing(3.5),
              marginRight: theme.spacing(3.5),
              borderRight: `solid 1px ${theme.palette.border.main}`,
            }}
          />
          <Box>
            <Typography
              fontSize="14px"
              component="div"
              sx={{
                color: theme.palette.primaryText.main,
                fontWeight: "bold",
                paddingRight: theme.spacing(5.25),
              }}
            >
              {"Mach"}
            </Typography>
            <Typography
              fontSize="12px"
              component="div"
              sx={{
                color: theme.palette.primaryText.main,
                fontWeight: "bold",
                paddingRight: theme.spacing(5.25),
              }}
            >
              {"By Air Partner"}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {smallDrawer}
      </Drawer>
      <Box sx={{ display: { xs: "none", sm: "block" }, height: "100%" }}>
        {desktopDrawer}
      </Box>
    </Box>
  );
}

export default NavigationDrawer;
