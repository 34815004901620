import React from "react";
import Layout from "./Layout";
import { Button, useTheme } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "react-oidc-context";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import CenterComponent from "../utilities/CenterContent";


function UserNotFoundPage() {
    const auth = useAuth();
    const theme = useTheme();

    return (
        <Layout>
            <CenterComponent>
                <PageTitle>
                    Hmm... Who are you?
                </PageTitle>
                <PageTypography>Your Login was successfull however your account is not set up with JetCard Portal.</PageTypography>
                <PageTypography>Please Contact your Manager if this is expected.</PageTypography>
                <Button
                    sx={{ marginTop: theme.spacing(2), alignSelf: "start" }}
                    variant="outlined"
                    color="primaryText"
                    endIcon={<LogoutIcon />}
                    onClick={() => {
                        auth.signoutPopup();
                    }}
                >
                    SIGN OUT
                </Button>
            </CenterComponent>
        </Layout>
    );
}

export default UserNotFoundPage;
