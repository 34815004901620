import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  useTheme,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from "@mui/material";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import { ROLE_LIST } from "../../constants";
import {
  editPortalUser,
  deactivatePortalUser,
  activatePortalUser,
} from "../../API/Admin";
import { useQueryClient } from "@tanstack/react-query";
import { JetCardTechSupportContact } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import { IsValidEmail } from "../../utils/tools";

function ManageUserPanel({ open, setOpenManageUserPanel, selectedUser }) {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [validDetails, setValidDetails] = useState(false);
  const [manageUserError, setManageUserError] = useState(false);
  const [manageUserLoading, setManageUserLoading] = useState(false);
  const [activateDeactivateLoading, setactivateDeactivateLoading] =
    useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setEmail(selectedUser?.email ?? "");
    setRoleId(selectedUser?.role?.id ?? "");
  }, [selectedUser]);

  useEffect(() => {
    setValidDetails(IsValidEmail(email) && roleId !== "");
  }, [email, roleId]);

  const valuesChanged =
    email !== selectedUser?.email || roleId !== selectedUser?.role.id;

  const canSubmitTooltip = valuesChanged
    ? validDetails
      ? ""
      : "The Details Provided are Invalid"
    : "Details Must be Changed to Submit";

  const submitForm = () => {
    setManageUserLoading(true);
    const { promise, abort } = editPortalUser(selectedUser?.id, email, roleId);
    promise.then(() => {
      setManageUserLoading(false);
      queryClient.invalidateQueries({ queryKey: ["getPortalUsers"] });
      setManageUserError(false);
      setOpenManageUserPanel(false);
    });
    promise.catch(() => {
      setManageUserLoading(false);
      setManageUserError(true);
    });
    return () => abort();
  };

  const confirmDeactivate = () => {
    setactivateDeactivateLoading(true);
    const { promise, abort } = deactivatePortalUser(selectedUser.id);
    promise.then(() => {
      setactivateDeactivateLoading(false);
      queryClient.invalidateQueries({ queryKey: ["getPortalUsers"] });
      setManageUserError(false);
      setOpenManageUserPanel(false);
    });
    promise.catch(() => {
      setactivateDeactivateLoading(false);
      setManageUserError(true);
    });
    return () => abort();
  };

  const confirmActivate = () => {
    setactivateDeactivateLoading(true);
    const { promise, abort } = activatePortalUser(selectedUser.id);
    promise.then(() => {
      setactivateDeactivateLoading(false);
      queryClient.invalidateQueries({ queryKey: ["getPortalUsers"] });
      setManageUserError(false);
      setOpenManageUserPanel(false);
    });
    promise.catch(() => {
      setactivateDeactivateLoading(false);
      setManageUserError(true);
    });
    return () => abort();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpenManageUserPanel(false)}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "400px" },
        },
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageTitle type="medium" sx={{ color: theme.palette.secondary.main }}>
          Manage User
        </PageTitle>
        <PageTypography
          sx={{
            color: theme.palette.primaryText.main,
            marginBottom: theme.spacing(6),
          }}
        >
          Configure your User Below.
        </PageTypography>
        <TextField
          id="Email"
          label="E-Mail"
          variant="outlined"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          helperText="Note: This is used as the Tennant Identifier"
          value={email}
        />
        <FormControl fullWidth sx={{ marginTop: theme.spacing(4) }}>
          <InputLabel id="role-label">User Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            label="User Role"
            onChange={(e) => setRoleId(e.target.value)}
            value={roleId}
          >
            {ROLE_LIST.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {manageUserError && (
          <Box sx={{ marginTop: theme.spacing(3) }}>
            <PageTypography
              fontWeight="bold"
              sx={{ color: theme.palette.error.main }}
            >
              Error Modifying User, Please try again.
            </PageTypography>
            <PageTypography sx={{ color: theme.palette.error.main }}>
              If this continues, contact our Support Team:{" "}
              {JetCardTechSupportContact}
            </PageTypography>
          </Box>
        )}

        <Box
          sx={{
            justifyContent: "end",
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
          }}
        >
          <Tooltip title={canSubmitTooltip} followCursor>
            <span>
              <LoadingButton
                color="secondary"
                variant="outlined"
                endIcon={<DoneAllIcon />}
                disabled={!validDetails || !valuesChanged}
                onClick={submitForm}
                loading={manageUserLoading}
                loadingPosition="end"
                fullWidth
              >
                Submit Changes
              </LoadingButton>
            </span>
          </Tooltip>
          {selectedUser?.isActive ? (
            <LoadingButton
              color="secondary"
              variant="outlined"
              endIcon={<PersonOffIcon />}
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              onClick={confirmDeactivate}
              loading={activateDeactivateLoading}
              loadingPosition="end"
              fullWidth
            >
              Deactivate User
            </LoadingButton>
          ) : (
            <LoadingButton
              color="secondary"
              variant="outlined"
              endIcon={<PersonIcon />}
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              onClick={confirmActivate}
              loading={activateDeactivateLoading}
              loadingPosition="end"
              fullWidth
            >
              Activate User
            </LoadingButton>
          )}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setOpenManageUserPanel(false);
            }}
            endIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ManageUserPanel;
