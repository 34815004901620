import React, { useEffect } from "react";
import { Skeleton, Box, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { PageSubTitle, PageTypography } from "./PageTitle";
import CircularProgressWithIcon from "../common/CircularProgressWithIcon";

function QueryHandlerContainer({
  query,
  height,
  width,
  children,
  loadStyle = "skeleton",
}) {
  const theme = useTheme();

  if (query?.isLoading === true && loadStyle === "skeleton") {
    return (
      <Skeleton
        variant="rounded"
        height={height}
        width={width}
        sx={{ marginTop: theme.spacing(1) }}
      ></Skeleton>
    );
  }
  if (query?.isLoading === true && loadStyle === "circular") {
    return <CircularProgressWithIcon />;
  }
  if (query?.isError === true) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: theme.spacing(4),
          alignItems: "center",
          padding: "16px",
        }}
      >
        <ErrorIcon
          sx={{ fontSize: "2.5em", color: theme.palette.error.main }}
        />
        <PageSubTitle sx={{ marginTop: theme.spacing(1) }}>Error</PageSubTitle>
        <PageTypography sx={{ textAlign: "center" }}>
          Please Contact 'jetcardtechsupport@airpartner.com' if this continues.
        </PageTypography>
      </Box>
    );
  }

  return <>{children}</>;
}

export default QueryHandlerContainer;
