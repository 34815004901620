import React from "react";
import { CardContent, CardActions, Button, Tooltip, Box } from "@mui/material";
import { PageSubTitle } from "../utilities/PageTitle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ManageItineraryActionsSection({
  itineraryId,
  itineraryStatus,
  handleItineraryCreateEditClick,
  openViewItineraryPanel,
  title,
  disableModify = false,
}) {
  return (
    <>
      {!itineraryId ? (
        <CardActions sx={{ alignContent: "center" }}>
          <PageSubTitle type="medium" sx={{ margin: "0 8px" }}>
            {title}
          </PageSubTitle>
          <Tooltip
            title={
              disableModify
                ? "This Itinerary can no longer be modified"
                : undefined
            }
          >
            <Box sx={{ marginLeft: "auto !important" }}>
              <Button
                color="secondary"
                endIcon={<AddIcon />}
                onClick={handleItineraryCreateEditClick}
                disabled={disableModify}
              >
                Create
              </Button>
            </Box>
          </Tooltip>
        </CardActions>
      ) : (
        <>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 0,
            }}
          >
            <PageSubTitle
              type="medium"
              sx={{ marginTop: 0, marginRight: "auto" }}
            >
              {title}
            </PageSubTitle>
            <PageSubTitle sx={{ marginTop: 0, marginBottom: 0 }} type="small">
              {itineraryStatus}
            </PageSubTitle>
          </CardContent>
          <CardContent sx={{ display: "flex" }}>
            <Button
              color="secondary"
              onClick={() => openViewItineraryPanel(itineraryId)}
              variant="outlined"
              startIcon={<VisibilityIcon />}
            >
              View
            </Button>
            <Tooltip
              title={
                disableModify
                  ? "This Itinerary can no longer be modified"
                  : undefined
              }
            >
              <Box sx={{ marginLeft: "auto !important" }}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleItineraryCreateEditClick}
                  endIcon={<EditIcon />}
                  disabled={disableModify}
                >
                  Edit
                </Button>
              </Box>
            </Tooltip>
          </CardContent>
        </>
      )}
    </>
  );
}

export default ManageItineraryActionsSection;
