import React from 'react';
import { Box, useTheme } from '@mui/material';

function CenterComponent({ children, widthSetting, sx, ...rest }) {
    const theme = useTheme();

    const width = (() => {
        switch (widthSetting) {
            case 'large':
                return "1300px"
            default: return "1000px"
        }
    })();


    return (
        <Box sx={{
            width: "100%",
            maxWidth: width,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            ...sx
        }}>
            {children}
        </Box>
    );
};

export default CenterComponent;