import React, { useState } from "react";
import { CardContent, Button, TextField, Alert } from "@mui/material";
import { PageSubTitle } from "../utilities/PageTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WalletIcon from "@mui/icons-material/Wallet";
import ViewWalletItineraryPanel from "../ItineraryConstruction/ViewWalletItineraryPanel";
import { issueWalletItineraries } from "../../API/Itineraries";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { IsValidEmail } from "../../utils/tools";
import { JetCardTechSupportContact } from "../../constants";

function WalletItineraryActionsSection({ scheduleId }) {
  const [viewOpen, setViewOpen] = useState(false);
  const [issueWalletSectionOpen, setIssueWalletSectionOpen] = useState(false);
  const [issueWalletEmail, setIssueWalletEmail] = useState("");
  const [issueWalletLoading, setIssueWalletLoading] = useState(false);
  const [issueWalletEmailError, setIssueWalletEmailError] = useState(false);

  const handleCloseIssueWalletSection = () => {
    setIssueWalletSectionOpen(false);
    setIssueWalletEmail("");
    setIssueWalletEmailError(false);
  };

  const issueWalletItinerary = () => {
    setIssueWalletLoading(true);
    const { promise, abort } = issueWalletItineraries(
      scheduleId,
      issueWalletEmail
    );
    promise.then(() => {
      setIssueWalletLoading(false);
      handleCloseIssueWalletSection();
      setIssueWalletEmailError(false);
    });
    promise.catch(() => {
      setIssueWalletLoading(false);
      setIssueWalletEmailError(true);
    });
    return () => abort();
  };

  return (
    <>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 0,
        }}
      >
        <PageSubTitle type="medium" sx={{ marginTop: 0, marginRight: "auto" }}>
          Wallet Itinerary
        </PageSubTitle>
      </CardContent>
      {issueWalletSectionOpen ? (
        <>
          <Alert severity="info" variant="outlined" color="secondary">
            Once a customer has added the pass to their wallet, this will update
            as the Itinerary Changes.
          </Alert>
          <CardContent>
            <TextField
              label="E-mail"
              onChange={(event) => setIssueWalletEmail(event.target.value)}
              fullWidth
            />
          </CardContent>
          {issueWalletEmailError ? (
            <Alert severity="error">
              Error! If this continues, contact our Support Team:{" "}
              {JetCardTechSupportContact}
            </Alert>
          ) : (
            <></>
          )}
          <CardContent sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={issueWalletLoading}
              sx={{ justifySelf: "start" }}
              startIcon={<CloseIcon />}
              onClick={handleCloseIssueWalletSection}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{ marginLeft: "auto !important" }}
              disabled={!IsValidEmail(issueWalletEmail)}
              loading={issueWalletLoading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              onClick={issueWalletItinerary}
            >
              Submit
            </LoadingButton>
          </CardContent>
        </>
      ) : (
        <CardContent sx={{ display: "flex" }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setViewOpen(true)}
            startIcon={<VisibilityIcon />}
          >
            View
          </Button>
          <Button
            color="secondary"
            onClick={() => setIssueWalletSectionOpen(true)}
            variant="outlined"
            sx={{ marginLeft: "auto !important" }}
            endIcon={<WalletIcon />}
          >
            Issue to Wallet
          </Button>
        </CardContent>
      )}

      <ViewWalletItineraryPanel
        open={viewOpen}
        handleClose={() => setViewOpen(false)}
        scheduleId={scheduleId}
      />
    </>
  );
}

export default WalletItineraryActionsSection;
