import {
  secureJsonFetch,
  securePostWithJsonResult,
} from "../utils/secureFetch";
import { JetCardApiUri } from "../constants";

function getEstimatesFormData() {
  return secureJsonFetch(
    JetCardApiUri + "api/v1.0/portalBookingEstimate/getEstimatesFormData"
  );
}

function getAirportSearch(_filter) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalBookingEstimate/getAirportSearch?filter=${_filter}`
  );
}

function getBookingEstimate(_bookingData) {
  return securePostWithJsonResult(
    JetCardApiUri + `api/v1.0/portalBookingEstimate/getBookingEstimate`,
    _bookingData
  );
}

export { getEstimatesFormData, getAirportSearch, getBookingEstimate };
