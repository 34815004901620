import { secureJsonFetch, securePost } from "../utils/secureFetch";
import { JetCardApiUri } from "../constants";

//----App Context---------------------------------------------------------------------------------------------------------------
function fetchUserDetails() {
    return secureJsonFetch(JetCardApiUri + "api/v1.0/userContext");
}

//----Portal User Manage--------------------------------------------------------------------------------------------------------
function getPortalUsers() {
    return secureJsonFetch(JetCardApiUri + "api/v1.0/portalUserManage/getPortalUsers");
}

function addPortalUser(_email, _roleId) {
    return securePost(JetCardApiUri + "api/v1.0/portalUserManage/addPortalUser", { Email: _email, RoleId: _roleId });
}

function editPortalUser(_userId, _email, _roleId) {
    return securePost(JetCardApiUri + "api/v1.0/portalUserManage/editPortalUser", { UserId: _userId, Email: _email, RoleId: _roleId });
}

function deactivatePortalUser(userId) {
    return securePost(JetCardApiUri + "api/v1.0/portalUserManage/deactivatePortalUser", userId);
}

function activatePortalUser(userId) {
    return securePost(JetCardApiUri + "api/v1.0/portalUserManage/activatePortalUser", userId);
}

//----App User Manage-----------------------------------------------------------------------------------------------------------
function getAppUsers() {
    return secureJsonFetch(JetCardApiUri + "api/v1.0/appUserManage/getAppUsers");
}

function addAppUser(_data) {
    return securePost(JetCardApiUri + "api/v1.0/appUserManage/addAppUser", { ..._data });
}

function deleteAppUser(userId) {
    return securePost(JetCardApiUri + "api/v1.0/appUserManage/deleteAppUser", userId);
}

function getDataverseConnectionAccounts(_nameQuery) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/dataverseConnection/getAccounts?nameQuery=${_nameQuery}`);
}

function getDataverseConnectionContacts(_nameQuery) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/dataverseConnection/getContacts?nameQuery=${_nameQuery}`);
}



export {
    fetchUserDetails,

    getPortalUsers,
    addPortalUser,
    editPortalUser,
    activatePortalUser,
    deactivatePortalUser,

    getAppUsers,
    addAppUser,
    deleteAppUser,
    getDataverseConnectionAccounts,
    getDataverseConnectionContacts
};