import React, { useState } from 'react';
import Layout from '../common/Layout';
import CenterComponent from '../utilities/CenterContent';
import BookingRequestsTable from './BookingRequestsTable';
import MoreDetailsPanel from './MoreDetailsPanel';

function BookingRequestsPage({ }) {

    const [moreDetailsPanelOpen, setMoreDetailsPanelOpen] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(undefined);

    const handleRowClick = (rowId) => {
        setSelectedRowId(rowId);
        setMoreDetailsPanelOpen(true);
    }

    const closeMoreDetailsPanel = () => {
        setMoreDetailsPanelOpen(false);
        setSelectedRowId(undefined);
    }

    return (
        <Layout>
            <CenterComponent>
                <BookingRequestsTable handleRowClick={handleRowClick} />
            </CenterComponent>
            <MoreDetailsPanel open={moreDetailsPanelOpen} closeMoreDetailsPanel={closeMoreDetailsPanel} selectedRowId={selectedRowId} />
        </Layout>
    );
};

export default BookingRequestsPage;