import React, { useState, useEffect } from "react";
import HeaderBar from "./HeaderBar/index";
import { useResolvedPath } from "react-router-dom";
import appRoutes from "../../AppRoutes";
import NavigationDrawer from "./NavigationDrawer";
import { Box, useTheme } from "@mui/material";
import { RoleView, getRoleViewName, useUserContext } from "./SecureComponent";

function Layout({ children, navItems }) {
  const theme = useTheme();
  const path = useResolvedPath();
  let route = appRoutes.find((x) =>
    path.pathname.startsWith(x.pathRaw ?? x.path)
  );
  if (!route) route = appRoutes.find((x) => x.index === true);
  const drawerWidth =
    getRoleViewName(route.settings.roles, useUserContext()) === "children"
      ? 100
      : 0;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    if (route.index) {
      document.title = "Mach";
    } else {
      document.title = `Mach / ${route.settings.title}`;
    }
  }, [route]);

  return (
    <Box
      sx={{
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        background: theme.palette.background.main,
      }}
    >
      <HeaderBar
        settings={route.settings}
        navItems={navItems}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        sx={{ display: "flex", width: "100%", flex: 1, flexDirection: "row" }}
      >
        <RoleView key="roleView" roles={route.settings.roles}>
          <NavigationDrawer
            routeTitle={route.settings.title}
            mobileOpen={mobileOpen}
            handleDrawerTransitionEnd={handleDrawerTransitionEnd}
            handleDrawerClose={handleDrawerClose}
            drawerWidth={drawerWidth}
          />
        </RoleView>
        <Box sx={{ position: "relative", flex: "1 0 0" }}>
          <Box
            component="main"
            sx={{
              overflowY: "auto",
              height: "100%",
              width: "100%",
              position: "absolute",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{ padding: theme.spacing(6), paddingTop: theme.spacing(8) }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
