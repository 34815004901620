
//----- General -------------------------------------------------------

const mapUpdatedItineraryDtoToFormValues = (dto) => {
    return {
        tripDate: dto.tripDate,
        departureAirport: dto.departureAirport,
        arrivalAirport: dto.arrivalAirport,
        departureTime: dto.departureTime,
        arrivalTime: dto.arrivalTime,
        flightTime: dto.flightTime,
    }
}

const COLLECTION_METHOD = {
    EXTERNAL: "getUpdatedScheduleDetails",
    INITIAL_EXSISTING: "getCurrentInitialItineraryValues",
    FINAL_EXSISTING: "getCurrentFinalItineraryValues",
}

//----- Initial -------------------------------------------------------

const mapInitialItineraryDtoToFormValues = (dto) => {
    return {
        cardHours: dto?.cardHours ?? 0,
        tripDate: dto.tripDate,
        departureAirport: dto.departureAirport,
        arrivalAirport: dto.arrivalAirport,
        departureTime: dto.departureTime,
        arrivalTime: dto.arrivalTime,
        flightTime: dto.flightTime,
        leadPassenger: dto.passengers.find(p => p.isLeadPassenger === true)?.passengerId ?? '',
    }
}

const mapFormValuesToInitialItineraryDto = (scheduleId, values, passengers) => {
    passengers.forEach(passenger => {
        passenger.isLeadPassenger = (passenger.passengerId === values.leadPassenger)
    });

    return {
        scheduleId: scheduleId,
        cardHours: values.cardHours,
        tripDate: values.tripDate,
        departureAirport: values.departureAirport,
        arrivalAirport: values.arrivalAirport,
        departureTime: values.departureTime,
        arrivalTime: values.arrivalTime,
        flightTime: values.flightTime,
        passengers: passengers,
    }
}

//----- Final -------------------------------------------------------

const mapFinalItineraryDtoToFormValues = (dto) => {
    return {
        cardHours: dto?.cardHours ?? 0,
        tripDate: dto.tripDate,
        departureAirport: dto.departureAirport,
        arrivalAirport: dto.arrivalAirport,
        departureTime: dto.departureTime,
        arrivalTime: dto.arrivalTime,
        flightTime: dto.flightTime,

        departureHandlingAgent: dto.departureHandlingAgent,
        arrivalHandlingAgent: dto.arrivalHandlingAgent,
        departureContactNumber: dto.departureContactNumber,
        arrivalContactNumber: dto.arrivalContactNumber,
        departureMeetingPoint: dto.departureMeetingPoint,
        arrivalMeetingPoint: dto.arrivalMeetingPoint,
        aircraft: dto.aircraft,
        registration: dto.registration,
        seating: dto.seating,
        baggage: dto.baggage,
        captain: dto.captain,
        flightAttendants: dto.flightAttendants,
        catering: dto.catering,
        firstOfficer: dto.firstOfficer,
        newspapers: dto.newspapers,
        groundTransport: dto.groundTransport,
        operationalInfo: dto.operationalInfo,
        leadPassenger: dto.passengers.find(p => p.isLeadPassenger === true)?.passengerId ?? '',
    }
}

const mapFormValuesToFinalItineraryDto = (scheduleId, values, passengers) => {
    passengers.forEach(passenger => {
        passenger.isLeadPassenger = (passenger.passengerId === values.leadPassenger)
    });

    return {
        scheduleId: scheduleId,
        cardHours: values.cardHours,
        tripDate: values.tripDate,
        departureAirport: values.departureAirport,
        arrivalAirport: values.arrivalAirport,
        departureTime: values.departureTime,
        arrivalTime: values.arrivalTime,
        flightTime: values.flightTime,
        departureHandlingAgent: values.departureHandlingAgent,
        arrivalHandlingAgent: values.arrivalHandlingAgent,
        departureContactNumber: values.departureContactNumber,
        arrivalContactNumber: values.arrivalContactNumber,
        departureMeetingPoint: values.departureMeetingPoint,
        arrivalMeetingPoint: values.arrivalMeetingPoint,
        aircraft: values.aircraft,
        registration: values.registration,
        seating: values.seating,
        baggage: values.baggage,
        captain: values.captain,
        flightAttendants: values.flightAttendants,
        catering: values.catering,
        firstOfficer: values.firstOfficer,
        newspapers: values.newspapers,
        groundTransport: values.groundTransport,
        operationalInfo: values.operationalInfo,
        passengers: passengers,
    }
}


export {
    mapUpdatedItineraryDtoToFormValues,
    COLLECTION_METHOD,

    mapInitialItineraryDtoToFormValues,
    mapFormValuesToInitialItineraryDto,

    mapFinalItineraryDtoToFormValues,
    mapFormValuesToFinalItineraryDto,
}