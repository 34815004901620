import React from "react";
import { PageTypography } from "../utilities/PageTitle";
import {
  CardContent,
  Divider,
  Card,
  useTheme,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";

function EstimateDisplay({ data }) {
  const theme = useTheme();
  return (
    <>
      <CardContent>
        {data.estimatedSuccessfully ? (
          data.isCashCard ? (
            <PageTypography
              sx={{
                textAlign: "center",
                color: theme.palette.secondary.main,
              }}
              variant="h3"
            >
              {data.totalEstimatedCostDisplay}
            </PageTypography>
          ) : (
            <>
              <PageTypography
                sx={{
                  textAlign: "center",
                  color: theme.palette.secondary.main,
                }}
                variant="h4"
              >
                {data.totalEstimatedHoursDeductionDisplay}
              </PageTypography>
              <PageTypography sx={{ textAlign: "center" }} variant="subtitle1">
                - OR -
              </PageTypography>
              <PageTypography sx={{ textAlign: "center" }}>
                {data.totalEstimatedCostDisplay}
              </PageTypography>
            </>
          )
        ) : (
          <Alert severity="warning">
            <AlertTitle>Unable to estimate booking.</AlertTitle>
            See Estimate log for details.
          </Alert>
        )}
      </CardContent>
      <Divider />
      <CardContent>
        <PageTypography sx={{ marginBottom: theme.spacing(2) }}>
          Estimate Log:
        </PageTypography>
        <Card
          sx={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {data.processLog.map((log, index) => (
            <Box
              sx={{
                padding: theme.spacing(1),
              }}
              key={index}
            >
              <PageTypography
                variant="body2"
                sx={{
                  fontFamily:
                    '"Consolas", "Monaco", "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace',
                }}
              >
                {">   "}
                {log}
              </PageTypography>
            </Box>
          ))}
        </Card>
      </CardContent>
    </>
  );
}

export default EstimateDisplay;
