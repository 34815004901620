import React, { useEffect } from "react";
import { AuthProvider } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";
import appRoutes from "./AppRoutes";
import JetCardPortalThemeProvider from "./styles/JetCardPortalThemeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const queryClient = new QueryClient();
  const acrValues = process.env.REACT_APP_OIDC_EXTERNAL_PROVIDER
    ? "idp:" + process.env.REACT_APP_OIDC_EXTERNAL_PROVIDER
    : undefined;
  const isDevelopment = process.env.NODE_ENV === "development";
  const authConfig = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: window.location.origin,
    response_type: "code",
    automaticSilentRenew: true,
    acr_values: acrValues,
    scope: process.env.REACT_APP_OIDC_CLIENT_ID,
    onSigninCallback: () => {
      let returnUrl = localStorage.getItem("returnUrl");
      if (returnUrl) {
        localStorage.removeItem("returnUrl");
        window.location.href = returnUrl;
      } else {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    },
  };

  return (
    <AuthProvider {...authConfig}>
      <QueryClientProvider client={queryClient}>
        <JetCardPortalThemeProvider>
          <Routes>
            {appRoutes.map((route, index) => {
              const { element, settings, ...rest } = route;
              return (
                <Route
                  sx={{ height: "100%" }}
                  key={index}
                  {...rest}
                  element={element}
                  exact
                />
              );
            })}
          </Routes>
        </JetCardPortalThemeProvider>
        {isDevelopment && <ReactQueryDevtools />}
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
