import React from "react";
import Layout from "./Layout";
import { Box, useTheme } from "@mui/material";
import PageTitle, { PageSubTitle } from "../utilities/PageTitle";
import CenterComponent from "../utilities/CenterContent";
import AirPartnerIcon from "./Icons/AirPartnerIcon";
import CircularProgressWithIcon from "./CircularProgressWithIcon";

function LoadingPage({ }) {
    const theme = useTheme();
    return (
        <Layout>
            <CenterComponent>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "10%"
                }}>
                    <AirPartnerIcon sx={{ fontSize: 50, marginBottom: theme.spacing(4) }} />
                    <PageTitle variant="h4">
                        Mach
                    </PageTitle>
                    <PageSubTitle variant="h6" sx={{ marginTop: 0 }}>
                        By Air Partner
                    </PageSubTitle>
                    <CircularProgressWithIcon />
                </Box>
            </CenterComponent>
        </Layout>
    );
}

export default LoadingPage;