import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  useTheme,
  Divider,
  Box,
  CircularProgress,
  Alert,
  CardContent,
} from "@mui/material";
import { PageSubTitle, PageTypography } from "../utilities/PageTitle";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useQuery } from "@tanstack/react-query";
import { getBookingEstimate } from "../../API/Estimates";
import EstimateDisplay from "./EstimateDisplay";

function CalculateWidget({ getBookingEstimateQuery }) {
  const theme = useTheme();
  const getBookingEstimateQueryData = getBookingEstimateQuery?.data;

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "5px",
        backgroundColor: theme.palette.backgroundContrast.secondary,
        border: "none",
      }}
      variant="outlined"
    >
      <CardHeader
        avatar={<CalculateIcon sx={{ fontSize: "2em" }} />}
        title={
          <PageSubTitle
            variant="subtitle1"
            sx={{ marginTop: theme.spacing(1), marginBottom: 0 }}
          >
            Calculate
          </PageSubTitle>
        }
      />
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {getBookingEstimateQuery.isLoading ? (
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <CircularProgress
              sx={{ margin: `${theme.spacing(6)} auto` }}
              color="secondary"
              size={70}
            />
            <PageTypography sx={{ textAlign: "center" }}>
              Generating...
            </PageTypography>
          </CardContent>
        ) : !getBookingEstimateQueryData ? (
          <CardContent>
            <Alert severity="info" color="secondary">
              Complete all Details for an Estimate
            </Alert>
          </CardContent>
        ) : (
          <EstimateDisplay data={getBookingEstimateQueryData} />
        )}
      </Box>
    </Card>
  );
}

export default CalculateWidget;
