import React from "react";
import Layout from "./Layout";
import CenterComponent from "../utilities/CenterContent";
import PageTitle, { PageTypography } from "../utilities/PageTitle";

function SomethingWentWrong() {

    return (
        <Layout>
            <CenterComponent>
                <PageTitle>
                    Oops... Something Went Wrong
                </PageTitle>
                <PageTypography>The Action you are trying to complete is Invalid.</PageTypography>
                <PageTypography>Please contact jetcardtechsupport@airpartner.com if this issue persists.</PageTypography>
            </CenterComponent>
        </Layout>
    );
}

export default SomethingWentWrong;
