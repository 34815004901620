import SecureComponent from "./components/common/SecureComponent";
import SomethingWentWrong from "./components/common/SomethingWentWrong";
import InvalidPage from "./components/common/InvalidPage";
import HomePage from "./components/HomePage";
import { ROLES } from "./constants";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MachUserManagePage from "./components/MachUserManagePage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AppUserManagePage from "./components/AppUserManagementPage";
import BookingRequestsPage from "./components/BookingRequestsPage";
import FlightManagementPage from "./components/FlightManagementPage";
import FinalItineraryPage from "./components/FinalItineraryPage";
import FlightIcon from "@mui/icons-material/Flight";
import ReorderIcon from "@mui/icons-material/Reorder";
import BookingEstimatePage from "./components/BookingEstimatePage";
import CalculateIcon from "@mui/icons-material/Calculate";

export const routes = {
  home: () => appRoutes.find((obj) => obj.id === 0),
  bookingRequests: () => appRoutes.find((obj) => obj.id === 2),
  MachUserManagement: () => appRoutes.find((obj) => obj.id === 3),
  appUserManagement: () => appRoutes.find((obj) => obj.id === 4),
  flightManagement: () => appRoutes.find((obj) => obj.id === 5),
  finalItinerary: () => appRoutes.find((obj) => obj.id === 6),
  bookingEstimate: () => appRoutes.find((obj) => obj.id === 7),
  somethingWentWrong: () => appRoutes.find((obj) => obj.id === 98),
  invalidRoute: () => appRoutes.find((obj) => obj.id === 99),
};

const appRoutes = [
  {
    id: 0,
    index: true,
    element: (
      <SecureComponent
        roles={[ROLES.BROKER, ROLES.SUPER_USER, ROLES.ADMINISTRATOR]}
      >
        <HomePage />
      </SecureComponent>
    ),
    settings: {
      title: "Home",
      icon: <HomeIcon />,
      menuItem: true,
      roles: [ROLES.BROKER, ROLES.SUPER_USER, ROLES.ADMINISTRATOR],
      homeTileItem: false,
    },
  },
  {
    id: 2,
    path: "/booking-requests",
    element: (
      <SecureComponent roles={[ROLES.BROKER, ROLES.SUPER_USER]}>
        <BookingRequestsPage />
      </SecureComponent>
    ),
    settings: {
      title: "Booking Requests",
      menuItem: true,
      icon: <ListIcon />,
      roles: [ROLES.BROKER, ROLES.SUPER_USER],
      homeTileItem: true,
      description: "Manage, View and Process Booking Requests",
    },
  },
  {
    id: 3,
    path: "/mach-user-management",
    element: (
      <SecureComponent roles={[ROLES.SUPER_USER, ROLES.ADMINISTRATOR]}>
        <MachUserManagePage />
      </SecureComponent>
    ),
    settings: {
      title: "Mach User Management",
      icon: <ManageAccountsIcon />,
      menuItem: true,
      roles: [ROLES.SUPER_USER, ROLES.ADMINISTRATOR],
      homeTileItem: true,
      description: "Add, Edit, and Manage Mach users and their Roles",
    },
  },
  {
    id: 4,
    path: "/app-user-management",
    element: (
      <SecureComponent roles={[ROLES.SUPER_USER, ROLES.ADMINISTRATOR]}>
        <AppUserManagePage />
      </SecureComponent>
    ),
    settings: {
      title: "App User Management",
      icon: <ManageAccountsIcon />,
      menuItem: true,
      roles: [ROLES.SUPER_USER, ROLES.ADMINISTRATOR],
      homeTileItem: true,
      description: "Add, Remove and Edit Users on the JetCard App",
    },
  },
  {
    id: 5,
    path: "/flight-management/:scheduleId",
    pathRaw: "/flight-management/",
    generatePath: (_scheduleId) => `/flight-management/${_scheduleId}`,
    element: (
      <SecureComponent roles={[ROLES.BROKER, ROLES.SUPER_USER]}>
        <FlightManagementPage />
      </SecureComponent>
    ),
    settings: {
      title: "Flight Management",
      icon: <FlightIcon />,
      menuItem: false,
      roles: [ROLES.BROKER, ROLES.SUPER_USER],
      homeTileItem: false,
    },
  },
  {
    id: 6,
    path: "/final-itinerary/:scheduleId/:itineraryId",
    pathRaw: "/final-itinerary/",
    generatePath: (_scheduleId, _itineraryId) =>
      `/final-itinerary/${_scheduleId}/${_itineraryId}`,
    element: (
      <SecureComponent roles={[ROLES.BROKER, ROLES.SUPER_USER]}>
        <FinalItineraryPage />
      </SecureComponent>
    ),
    settings: {
      title: "Final Itinerary",
      icon: <ReorderIcon />,
      menuItem: false,
      roles: [ROLES.BROKER, ROLES.SUPER_USER],
      homeTileItem: false,
    },
  },
  {
    id: 7,
    path: "/booking-estimate",
    element: (
      <SecureComponent roles={[ROLES.SUPER_USER, ROLES.BROKER]}>
        <BookingEstimatePage />
      </SecureComponent>
    ),
    settings: {
      title: "Estimate Booking",
      icon: <CalculateIcon />,
      menuItem: true,
      roles: [ROLES.SUPER_USER, ROLES.BROKER],
      homeTileItem: true,
      description: "Estimate a booking price for a given journey.",
    },
  },
  //INSERT NEW ROUTES ABOVE THIS
  {
    id: 98,
    path: "/something-went-wrong",
    element: (
      <SecureComponent
        roles={[ROLES.BROKER, ROLES.SUPER_USER, ROLES.ADMINISTRATOR]}
      >
        <SomethingWentWrong />
      </SecureComponent>
    ),
    settings: {
      title: "Something Went Wrong",
      menuItem: false,
      roles: [ROLES.BROKER, ROLES.SUPER_USER, ROLES.ADMINISTRATOR],
      homeTileItem: false,
    },
  },
  {
    id: 99,
    path: "*",
    element: (
      <SecureComponent
        roles={[ROLES.BROKER, ROLES.SUPER_USER, ROLES.ADMINISTRATOR]}
      >
        <InvalidPage />
      </SecureComponent>
    ),
    settings: {
      title: "Invalid page",
      menuItem: false,
      homeTileItem: false,
    },
  },
];

export default appRoutes;
