import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Divider,
  Button,
  Alert,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import AddPassengerInput from "./AddPassengerInput";
import {
  linkPassengerToFlight,
  createAndLinkPassengerToFlight,
} from "../../API/FlightManagement";
import { JetCardTechSupportContact } from "../../constants";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import { LoadingButton } from "@mui/lab";
import { getPassengersForCustomerSchedules } from "../../API/FlightManagement";

function AddPassengerSection({
  setOpen,
  scheduleId,
  exsistingPassengerIds = [],
}) {
  const addPassengerFormDefaultValues = { id: "", firstName: "", lastName: "" };
  const [selectedPassenger, setSelectedPassenger] = useState(
    addPassengerFormDefaultValues
  );
  const [isNewPassenger, setIsNewPassenger] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [addPassengerError, setAddPassengerError] = useState(false);
  const [addPassengerLoading, setAddPassengerLoading] = useState(false);
  const queryClient = useQueryClient();

  const passengersForCustomerSchedulesQuery = useQuery({
    queryKey: ["getPassengersForCustomerSchedules", scheduleId],
    queryFn: () => getPassengersForCustomerSchedules(scheduleId).promise,
  });

  const hasFirstName = selectedPassenger?.firstName !== "";
  const hasLastName = selectedPassenger?.lastName !== "";

  useEffect(() => {
    setIsNewPassenger(
      selectedPassenger?.id === "" && (hasFirstName || hasLastName)
    );
    setCanSubmit(hasFirstName && hasLastName);
  }, [selectedPassenger]);

  const handleCancelAddPassenger = () => {
    setOpen(false);
  };

  const handleSubmitAddPassenger = () => {
    setAddPassengerLoading(true);
    const { promise, abort } = isNewPassenger
      ? createAndLinkPassengerToFlight(
          selectedPassenger.firstName,
          selectedPassenger.lastName,
          scheduleId
        )
      : linkPassengerToFlight(selectedPassenger.id, scheduleId);
    promise.then(() => {
      queryClient.invalidateQueries({
        queryKey: ["getFlightManagementPassengers", scheduleId],
      });
      queryClient.invalidateQueries({
        queryKey: ["getUpdatedScheduleDetails", scheduleId],
      });
      setAddPassengerError(false);
      setAddPassengerLoading(false);
      setOpen(false);
    });
    promise.catch(() => {
      setAddPassengerLoading(false);
      setAddPassengerError(true);
    });
    return () => abort();
  };

  const options =
    passengersForCustomerSchedulesQuery?.data?.filter(
      (passenger) => !exsistingPassengerIds.includes(passenger.id)
    ) ?? [];

  return (
    <QueryHandlerContainer
      query={passengersForCustomerSchedulesQuery}
      height="200px"
      width="100%"
    >
      <Alert severity="info" variant="outlined" color="secondary">
        Search to link an exsisting Passenger or hit '<b>Enter</b>' to confirm
        new Passenger Details.
      </Alert>
      <CardContent>
        <AddPassengerInput
          options={options}
          selectedPassenger={selectedPassenger}
          setSelectedPassenger={setSelectedPassenger}
          disabled={addPassengerLoading}
        />
      </CardContent>
      {isNewPassenger ? (
        <Alert severity="info" variant="outlined" color="secondary">
          A new passenger, '
          <b>
            {selectedPassenger.firstName} {selectedPassenger.lastName}
          </b>
          ' will be created.
        </Alert>
      ) : (
        <></>
      )}
      {addPassengerError ? (
        <Alert severity="error">
          Error! If this continues, contact our Support Team:{" "}
          {JetCardTechSupportContact}
        </Alert>
      ) : (
        <></>
      )}
      <Divider />
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={addPassengerLoading}
          sx={{ justifySelf: "start" }}
          startIcon={<ClearIcon />}
          onClick={handleCancelAddPassenger}
        >
          Cancel
        </Button>
        <Tooltip
          title={canSubmit ? "" : "Both First Name and Last Name are Required"}
        >
          <span>
            <LoadingButton
              variant="contained"
              color="secondary"
              disabled={!canSubmit}
              loading={addPassengerLoading}
              loadingPosition="end"
              endIcon={<AddIcon />}
              onClick={handleSubmitAddPassenger}
            >
              {isNewPassenger ? "Create and Link" : "Link"}
            </LoadingButton>
          </span>
        </Tooltip>
      </CardActions>
    </QueryHandlerContainer>
  );
}

export default AddPassengerSection;
