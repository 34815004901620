import { secureJsonFetch } from "../utils/secureFetch";
import { JetCardApiUri } from "../constants";

//----Booking Requests---------------------------------------------------------------------------------------------------------------

function getBookingRequests(_toDate, _fromDate) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalBookingRequests/getBookingRequests?FromDate=${_fromDate}&ToDate=${_toDate}`);
}

function getBookingRequestSummaryDetails(_opportunityId) {
    return secureJsonFetch(JetCardApiUri + `api/v1.0/portalBookingRequests/getBookingRequestSummaryDetails?OpportunityId=${_opportunityId}`);
}

function getBookingRequestOverviewGraphData() {
    return secureJsonFetch(JetCardApiUri + 'api/v1.0/portalBookingRequests/getBookingRequestOverviewGraphData');
}

export {
    getBookingRequests,
    getBookingRequestSummaryDetails,

    getBookingRequestOverviewGraphData,
};