import React, { useState } from "react";
import { getBookingRequests } from "../../API/Bookings";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Divider, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import MachDataTable from "../utilities/MachDataTable/MachDataTable";

function BookingRequestsTable({ handleRowClick }) {
  const theme = useTheme();
  const [toDate, setToDate] = useState(dayjs());
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "month"));

  const rows = useQuery({
    queryKey: ["getBookingRequests", { toDate, fromDate }],
    queryFn: () => getBookingRequests(toDate, fromDate).promise,
  });

  const columns = [
    { field: "customerName", headerName: "Customer" },
    {
      field: "legSummaries",
      hideFilter: true,
      headerName: "Journey Legs",
      renderCell: (params) =>
        params.row.legSummaries.map((leg, index) => {
          return <Box key={index}>{leg.title}</Box>;
        }),
    },
    {
      field: "bookingStatus",
      headerName: "Booking Status",
      minWidth: "160px",
    },
    {
      field: "createdOn",
      headerName: "Created On",
      hideFilter: true,
      renderCell: (params) =>
        dayjs(params?.row?.createdOn).format("DD/MM/YYYY"),
      minWidth: "110px",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom: theme.spacing(4),
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            value={fromDate}
            format="DD/MM/YYYY"
            maxDate={toDate}
            onChange={(newValue) => setFromDate(newValue)}
            sx={{ marginRight: theme.spacing(2) }}
            disabled={rows.isLoading}
          />
          <ArrowForwardIcon
            fontSize="medium"
            sx={{
              color: theme.palette.primary.main,
              marginRight: theme.spacing(2),
            }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            format="DD/MM/YYYY"
            onChange={(newValue) => setToDate(newValue)}
            minDate={fromDate}
            disableFuture
            disabled={rows.isLoading}
          />
        </LocalizationProvider>
      </Box>
      <QueryHandlerContainer query={rows} height="75vh" width="100%">
        <MachDataTable
          rows={rows?.data}
          columns={columns}
          titleField="customerName"
          tableName="BookingRequests"
          subtitleField="bookingStatus"
          defaultSortField="createdOn"
          defaultSortDirection="desc"
          onRowClick={(params) => {
            handleRowClick(params.opportunityId);
          }}
        />
      </QueryHandlerContainer>
    </Box>
  );
}

export default BookingRequestsTable;
