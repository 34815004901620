import { Grid2, useTheme } from "@mui/material";
import Layout from "../common/Layout";
import CenterComponent from "../utilities/CenterContent";
import React, { useEffect, useState } from "react";
import GeneralWidget from "./GeneralWidget";
import JourneyLegsWidget from "./JourneyLegsWidget";
import CalculateWidget from "./CalculateWidget";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getBookingEstimate } from "../../API/Estimates";

function BookingEstimatePage({}) {
  const theme = useTheme();
  const defaultValues = {
    aircraftCategory: "",
    jetCardId: null,
    journeyLegs: [
      {
        startAirport: null,
        endAirport: null,
        departureDateTime: null,
      },
    ],
  };
  const {
    register,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const [estimateValues, setEstimateValues] = useState(getValues());
  const watcher = watch();

  useEffect(() => {
    setEstimateValues(getValues());
  }, [watcher]);

  const getBookingEstimateQuery = useQuery({
    queryKey: ["getBookingEstimate", estimateValues],
    queryFn: () => getBookingEstimate(estimateValues).promise,
    enabled: () => {
      const journeyLegs = estimateValues.journeyLegs;
      return (
        estimateValues.aircraftCategory !== "" &&
        estimateValues.jetCardId !== null &&
        journeyLegs[journeyLegs.length - 1].startAirport !== null &&
        journeyLegs[journeyLegs.length - 1].endAirport !== null &&
        journeyLegs[journeyLegs.length - 1].departureDateTime !== null
      );
    },
  });

  return (
    <Layout>
      <CenterComponent widthSetting="large">
        <Grid2 spacing={6} sx={{ marginTop: theme.spacing(4) }} container>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 4,
            }}
          >
            <GeneralWidget
              register={register}
              disabled={false}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 4,
            }}
          >
            <JourneyLegsWidget
              getValues={getValues}
              control={control}
              disabled={false}
              setValue={setValue}
            />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 4,
            }}
          >
            <CalculateWidget
              watch={watch}
              getValues={getValues}
              getBookingEstimateQuery={getBookingEstimateQuery}
            />
          </Grid2>
        </Grid2>
      </CenterComponent>
    </Layout>
  );
}

export default BookingEstimatePage;
