import React, { useMemo, createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import getDesignTokens from "./theme";
import useLocalState from "../utils/useLocalState";
import { DISPLAY_MODES } from "../constants";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function JetCardPortalThemeProvider({ children }) {
  const [mode, setMode] = useLocalState("displayMode", DISPLAY_MODES.DARK);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((mode) =>
          mode === DISPLAY_MODES.LIGHT
            ? DISPLAY_MODES.DARK
            : DISPLAY_MODES.LIGHT
        );
      },
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default JetCardPortalThemeProvider;
