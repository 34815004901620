import React from "react";
import { getAppUsers } from "../../API/Admin";
import { useQuery } from "@tanstack/react-query";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import MachDataTable from "../utilities/MachDataTable/MachDataTable";

function UserTable({ setSelectedUserRow, selectedUserRow }) {
  const rowsQuery = useQuery({
    queryKey: ["getAppUsers"],
    queryFn: () => getAppUsers().promise,
  });

  const columns = [
    { field: "firstName", headerName: "First Name", hideFilter: true },
    { field: "lastName", headerName: "Surname", hideFilter: true },
    { field: "email", headerName: "E-Mail", hideFilter: true },
    { field: "jetCards", headerName: "JetCard(s)", hideFilter: true },
    { field: "brokers", headerName: "Broker(s)" },
    { field: "legalEntities", headerName: "Legal Entities" },
  ];
  return (
    <QueryHandlerContainer query={rowsQuery} height="75vh" width="100%">
      <MachDataTable
        rows={rowsQuery.data}
        columns={columns}
        titleField="firstName"
        tableName="AppUserManage"
        subtitleField="lastName"
        defaultSortField="firstName"
        exportOrientation="landscape"
        onRowClick={(user) => {
          if (user !== selectedUserRow) {
            setSelectedUserRow(user);
          } else {
            setSelectedUserRow(null);
          }
        }}
        selected={selectedUserRow}
      />
    </QueryHandlerContainer>
  );
}

export default UserTable;
