import React from "react";
import Layout from "./Layout";
import { Box, Button, useTheme } from "@mui/material";
import PageTitle, { PageSubTitle } from "../utilities/PageTitle";
import CenterComponent from "../utilities/CenterContent";
import AirPartnerIcon from "./Icons/AirPartnerIcon";

function LoginPage({ signInRedirect }) {
  const theme = useTheme();
  return (
    <Layout>
      <CenterComponent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10%",
          }}
        >
          <AirPartnerIcon
            sx={{ fontSize: 50, marginBottom: theme.spacing(4) }}
          />
          <PageTitle variant="h4">Mach</PageTitle>
          <PageSubTitle variant="h6" sx={{ marginTop: "0px" }}>
            By Air Partner
          </PageSubTitle>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => signInRedirect()}
            sx={{
              marginTop: theme.spacing(3),
              minWidth: "100px",
            }}
          >
            Sign In
          </Button>
        </Box>
      </CenterComponent>
    </Layout>
  );
}

export default LoginPage;
