import React from "react";
import {
  Card,
  CardHeader,
  useTheme,
  Divider,
  CardContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import { PageSubTitle } from "../utilities/PageTitle";
import FeedIcon from "@mui/icons-material/Feed";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import { useQuery } from "@tanstack/react-query";
import { getEstimatesFormData } from "../../API/Estimates";
import AircraftCategorySelect from "./AircraftCategorySelect";

function GeneralWidget({ register, control, errors, disabled, setValue }) {
  const theme = useTheme();
  const estimatesFormDataQuery = useQuery({
    queryKey: ["getEstimatesFormData"],
    queryFn: () => getEstimatesFormData().promise,
  });

  const estimatesFormDataQueryData = estimatesFormDataQuery.data;

  return (
    <QueryHandlerContainer
      height="400px"
      width="100%"
      query={estimatesFormDataQuery}
    >
      <Card
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor: theme.palette.backgroundContrast.secondary,
          border: "none",
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={<FeedIcon sx={{ fontSize: "2em" }} />}
          title={
            <PageSubTitle
              variant="subtitle1"
              sx={{ marginTop: theme.spacing(1), marginBottom: 0 }}
            >
              General
            </PageSubTitle>
          }
        />
        <Divider />

        <CardContent>
          <Autocomplete
            options={estimatesFormDataQueryData?.jetCards ?? []}
            {...register("jetCardId", { required: "This is Required" })}
            onChange={(event, value) =>
              setValue("jetCardId", value?.id ?? null)
            }
            isOptionEqualToValue={(option, value) => option?.id === value}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="JetCard"
                variant="outlined"
                fullWidth
                size="small"
              />
            )}
          />
        </CardContent>
        <Divider />
        <CardContent>
          <AircraftCategorySelect
            aircraftCategories={estimatesFormDataQueryData?.aircraftCategories}
            control={control}
            disabled={disabled}
            errors={errors}
          />
        </CardContent>
      </Card>
    </QueryHandlerContainer>
  );
}

export default GeneralWidget;
