import { Card, CardContent, useTheme } from '@mui/material';
import React from 'react';
import { PageSubTitle, PageTypography } from '../utilities/PageTitle';

function PageInfoTile({ title, description, onClick, icon }) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                width: "100%",
                borderRadius: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: theme.palette.backgroundContrast.secondary,
                ":hover": {
                    backgroundColor: theme.palette.backgroundContrast.main,
                }
            }}
            variant="outlined" onClick={() => { onClick() }}
        >
            <CardContent>
                {icon}
                <PageSubTitle variant="subtitle1" sx={{ marginTop: theme.spacing(1), color: theme.palette.secondary.main }}>{title}</PageSubTitle>
                <PageTypography>{description}</PageTypography>
            </CardContent>
        </Card>
    );
};

export default PageInfoTile;