import React from "react";
import { Controller } from "react-hook-form";
import {
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  FormHelperText,
} from "@mui/material";

function AircraftCategorySelect({ aircraftCategories, control, errors }) {
  const theme = useTheme();
  return (
    <>
      <Controller
        name="aircraftCategory"
        sx={{ width: "100%" }}
        control={control}
        rules={{ required: "This is Required" }}
        render={({ field }) => (
          <ToggleButtonGroup
            orientation="vertical"
            color="secondary"
            {...field}
            sx={{ width: "100%" }}
          >
            {aircraftCategories.map((aircraftCategory, index) => (
              <ToggleButton value={aircraftCategory} key={index}>
                {aircraftCategory}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      />
      <FormHelperText
        sx={{ paddingLeft: theme.spacing(3) }}
        error={!!errors?.aircraftCategory}
      >
        {errors?.aircraftCategory?.message}
      </FormHelperText>
    </>
  );
}

export default AircraftCategorySelect;
