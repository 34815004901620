import React, { useState } from "react";
import Layout from "../common/Layout";
import CenterComponent from "../utilities/CenterContent";
import UserTable from "./UserTable";
import { Alert, Link, useTheme } from "@mui/material";

const D365_CONTACTS_PAGE_LINK =
  "https://airpartner.crm11.dynamics.com/main.aspx?appid=157cfd20-6039-4ef8-8cb3-3782114eb506&pagetype=entitylist&etn=contact&viewid=00000000-0000-0000-00aa-000010001003&viewType=1039";

function AppUserManagePage() {
  const theme = useTheme();
  const [selectedUserRow, setSelectedUserRow] = useState(null);

  //NOTE: A LINK IS ADDED HERE TO ADD/REMOVE USERS - THIS SHOULD BE REMOVED IF FUNCTIONALITY IS BROUGHT INTO THE PORTAL
  return (
    <Layout>
      <CenterComponent widthSetting="large">
        <Alert
          severity="info"
          color="secondary"
          variant="outlined"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          To Add or Remove Jet Card App Users, Go to the{" "}
          <Link target="_blank" href={D365_CONTACTS_PAGE_LINK}>
            Contacts Section
          </Link>{" "}
          of the Air Partner JetCard / CMP Site
        </Alert>
        <UserTable
          setSelectedUserRow={setSelectedUserRow}
          selectedUserRow={selectedUserRow}
        />
      </CenterComponent>
    </Layout>
  );
}

export default AppUserManagePage;
