import React from 'react';
import { Box, useTheme } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place';
import { PageTypography } from '../utilities/PageTitle';
import FlightIcon from '@mui/icons-material/Flight';
import QueryHandlerContainer from '../utilities/QueryHandlerContainer';
import { useQuery } from "@tanstack/react-query";
import { getFlightManagementSummary } from "../../API/FlightManagement";



function FlightDisplayBanner({ scheduleId }) {
    const theme = useTheme();
    const flightManagementSummaryQuery = useQuery({
        queryKey: ["getFlightManagementSummary", scheduleId],
        queryFn: () => getFlightManagementSummary(scheduleId).promise,
    })
    const flightManagementSummary = flightManagementSummaryQuery?.data;

    return (
        <Box>
            <QueryHandlerContainer query={flightManagementSummaryQuery} width="100%" height="100px">
                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <Box>
                        <PlaceIcon color="secondary" fontSize="large" sx={{ margin: `0 ${theme.spacing(2)}` }} />
                    </Box>
                    <Box sx={{ border: `2px dashed ${theme.palette.secondary.main}`, flex: 1 }} />
                    <FlightIcon color="secondary" fontSize="large" sx={{ transform: 'rotate(90deg)', margin: `0 ${theme.spacing(1)}` }} />
                    <Box sx={{ border: `2px dashed ${theme.palette.secondary.main}`, flex: 1 }} />
                    <Box>
                        <PlaceIcon color="secondary" fontSize="large" sx={{ margin: `0 ${theme.spacing(2)}` }} />
                    </Box>            </Box>
                <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", textAlign: "center" }} >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", textAlign: "start" }}>
                        <PageTypography sx={{ color: theme.palette.primary.main }}>{flightManagementSummary?.departureAirportName}</PageTypography>
                        <PageTypography sx={{ color: theme.palette.primary.main }}>{flightManagementSummary?.departureCountry}</PageTypography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end", textAlign: "end" }}>
                        <PageTypography sx={{ color: theme.palette.primary.main }}>{flightManagementSummary?.arrivalAirportName}</PageTypography>
                        <PageTypography sx={{ color: theme.palette.primary.main }}>{flightManagementSummary?.arrivalCountry}</PageTypography>
                    </Box>
                </Box>
            </QueryHandlerContainer>
        </Box>
    );
};

export default FlightDisplayBanner;