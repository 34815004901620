import React, { useState } from "react";
import { Modal, useTheme, IconButton, Card, CardHeader } from "@mui/material";
import { PageSubTitle } from "../utilities/PageTitle";
import InitialItineraryModalContent from "./InitialItineraryModalContent";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import {
  getCurrentInitialItineraryValues,
  getUpdatedScheduleDetails,
} from "../../API/Itineraries";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";

function InitialItineraryModal({
  open,
  setOpen,
  scheduleId,
  initialItineraryId = null,
}) {
  const isNewItinerary = !initialItineraryId;
  const [canClose, setCanClose] = useState(true);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const getUpdatedScheduleDetailsQuery = useQuery({
    queryKey: ["getUpdatedScheduleDetails", scheduleId],
    queryFn: () => getUpdatedScheduleDetails(scheduleId).promise,
    enabled: isNewItinerary,
  });

  const getCurrentInitialItineraryValuesQuery = useQuery({
    queryKey: ["getCurrentInitialItineraryValues", scheduleId],
    queryFn: () => getCurrentInitialItineraryValues(scheduleId).promise,
    enabled: !isNewItinerary,
  });

  const itineraryData = isNewItinerary
    ? getUpdatedScheduleDetailsQuery.data
    : getCurrentInitialItineraryValuesQuery.data;

  const handleClose = () => {
    if (canClose) {
      setOpen(false);
    }
  };

  const handleCloseWithRefresh = () => {
    if (canClose) {
      queryClient.invalidateQueries({
        queryKey: ["getUpdatedScheduleDetails", scheduleId],
      });
      queryClient.invalidateQueries({
        queryKey: ["getCurrentInitialItineraryValues", scheduleId],
      });
      queryClient.invalidateQueries({
        queryKey: ["getFlightManagementItineraries", scheduleId],
      });
      setOpen(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        sx={{
          maxWidth: "90%",
          width: "fit-content",
          margin: "auto",
          marginTop: "8%",
        }}
      >
        <CardHeader
          title={`${isNewItinerary ? "Create" : "Edit"} Initial Itinerary`}
          titleTypographyProps={{ color: theme.palette.secondary.main }}
          action={
            <IconButton onClick={handleClose} sx={{ alignSelf: "start" }}>
              <CloseIcon />
            </IconButton>
          }
        />
        <QueryHandlerContainer
          width="100%"
          height="400px"
          query={
            isNewItinerary
              ? getUpdatedScheduleDetailsQuery
              : getCurrentInitialItineraryValuesQuery
          }
        >
          <InitialItineraryModalContent
            values={itineraryData}
            setCanClose={setCanClose}
            scheduleId={scheduleId}
            isNewItinerary={isNewItinerary}
            handleCloseWithRefresh={handleCloseWithRefresh}
          />
        </QueryHandlerContainer>
      </Card>
    </Modal>
  );
}

export default InitialItineraryModal;
