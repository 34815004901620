import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  useTheme,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import { ROLE_LIST, ROLES } from "../../constants";
import { addPortalUser } from "../../API/Admin";
import { useQueryClient } from "@tanstack/react-query";
import { JetCardTechSupportContact } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { LoadingButton } from "@mui/lab";
import { IsValidEmail } from "../../utils/tools";
import { useUserContext } from "../common/SecureComponent";

function AddUserPanel({ open, setOpenAddUserPanel }) {
  const theme = useTheme();
  const isSuperUser = useUserContext().role.id === ROLES.SUPER_USER;
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [validDetails, setValidDetails] = useState(false);
  const [addUserError, setAddUserError] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setValidDetails(IsValidEmail(email) && roleId !== "");
  }, [email, roleId]);

  const closePanel = () => {
    setOpenAddUserPanel(false);
    setRoleId("");
    setEmail("");
    setAddUserError(false);
    setValidDetails(false);
  };

  const submitForm = () => {
    if (validDetails) {
      setAddUserLoading(true);
      const { promise, abort } = addPortalUser(email, roleId);
      promise.then(() => {
        setAddUserLoading(false);
        queryClient.invalidateQueries({ queryKey: ["getPortalUsers"] });
        setAddUserError(false);
        closePanel();
      });
      promise.catch(() => {
        setAddUserLoading(false);
        setAddUserError(true);
      });
      return () => abort();
    }
  };

  const filteredRoles = isSuperUser
    ? ROLE_LIST
    : ROLE_LIST.filter((role) => role.id != ROLES.SUPER_USER);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => closePanel()}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "400px" },
        },
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageTitle type="medium" sx={{ color: theme.palette.secondary.main }}>
          Add User
        </PageTitle>
        <PageTypography
          sx={{
            color: theme.palette.primaryText.main,
            marginBottom: theme.spacing(6),
          }}
        >
          Configure your new User Below.
        </PageTypography>

        <TextField
          id="Email"
          label="E-Mail"
          variant="outlined"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          helperText="Note: This is used as the Tennant Identifier"
          value={email}
        />
        <FormControl fullWidth sx={{ marginTop: theme.spacing(4) }}>
          <InputLabel id="role-label">User Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            label="User Role"
            onChange={(e) => setRoleId(e.target.value)}
            value={roleId}
          >
            {filteredRoles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {addUserError && (
          <Box sx={{ marginTop: theme.spacing(3) }}>
            <PageTypography
              fontWeight="bold"
              sx={{ color: theme.palette.error.main }}
            >
              Error Adding User, Please try again.
            </PageTypography>
            <PageTypography sx={{ color: theme.palette.error.main }}>
              If this continues, contact our Support Team:{" "}
              {JetCardTechSupportContact}
            </PageTypography>
          </Box>
        )}
        <Box sx={{ justifyContent: "end", display: "flex", marginTop: "auto" }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              closePanel();
            }}
            sx={{ margin: theme.spacing(1), minWidth: "100px" }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{ margin: theme.spacing(1), minWidth: "100px" }}
            onClick={() => submitForm()}
            disabled={!validDetails}
            loading={addUserLoading}
            loadingPosition="end"
            endIcon={<DoneAllIcon />}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
}

export default AddUserPanel;
