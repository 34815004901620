import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as AirPartnerWhiteSvg } from "./air-partner-white.svg";
import { ReactComponent as AirPartnerBlackSvg } from "./air-partner-black.svg";
import { useTheme } from "@mui/material";

export function AirPartnerIcon({ ...props }) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            {theme.palette.mode === "dark" && <AirPartnerWhiteSvg />}
            {theme.palette.mode === "light" && <AirPartnerBlackSvg />}
        </SvgIcon>
    );
}

export default AirPartnerIcon;