import { useTheme, Grid2, Button } from "@mui/material";
import React from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import ConnectorVertical from "../utilities/ConnectorVertical";
import dayjs from "dayjs";
import AirportPicker from "./AirportPicker";

function JourneyLegInput({
  index,
  disabled,
  onChange,
  value,
  removeLeg,
  isReturn = false,
  isLast = false,
  minimumDate,
}) {
  const theme = useTheme();

  const handleChange = (newValue, type) => {
    switch (type) {
      case "startAirport":
        onChange({ ...value, startAirport: newValue });
        break;
      case "endAirport":
        onChange({ ...value, endAirport: newValue });
        break;
      case "startDateTime":
        onChange({ ...value, departureDateTime: dayjs(newValue) });
        break;
    }
  };

  return (
    <Grid2 container>
      <Grid2 size={1}>
        <ConnectorVertical />
      </Grid2>
      <Grid2 size={11}>
        <AirportPicker
          onChange={(event, value) => handleChange(value, "startAirport")}
          value={value.startAirport}
          disabled={disabled || index > 0}
          sx={{ paddingBottom: theme.spacing(2) }}
          label={"Departure Airport"}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            minDateTime={minimumDate}
            label="Departure Time"
            value={value.departureDateTime}
            onChange={(value) => handleChange(value, "startDateTime")}
            formatDensity="dense"
            slotProps={{ textField: { size: "small", fullWidth: true } }}
            sx={{ paddingBottom: theme.spacing(5) }}
          />
        </LocalizationProvider>
      </Grid2>
      <Grid2 size={1}>
        <ConnectorVertical isLast />
      </Grid2>
      <Grid2 size={11}>
        <AirportPicker
          onChange={(event, value) => handleChange(value, "endAirport")}
          value={value.endAirport}
          disabled={isReturn || disabled}
          label={"Arrival Airport"}
        />
      </Grid2>
      {index > 0 && isLast && (
        <Grid2 size={12}>
          <Button
            variant="outlined"
            onClick={removeLeg}
            sx={{ marginTop: theme.spacing(3) }}
            endIcon={<DeleteIcon />}
            size="small"
            fullWidth
          >
            Remove
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
}

export default JourneyLegInput;
