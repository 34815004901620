import React from "react";
import { Grid2, TextField, useTheme } from "@mui/material";

function ItinerarySectionExtended({ register, errors, isLoading = false }) {
  const theme = useTheme();

  const commonTextFieldProps = (key) => {
    return {
      variant: "outlined",
      fullWidth: true,
      required: true,
      disabled: isLoading,
      helperText: errors[key]?.message && "This is Required",
      error: !!errors?.[key],
      ...register(key, { required: "This is Required" }),
    };
  };

  return (
    (<Grid2 spacing={4} sx={{ paddingTop: theme.spacing(1) }} container>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("departureHandlingAgent")}
          label="Departure Handling Agent"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("departureContactNumber")}
          label="Departure Contact Number"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("departureMeetingPoint")}
          label="Departure Meeting Point"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("arrivalHandlingAgent")}
          label="Arrival Handling Agent"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("arrivalContactNumber")}
          label="Arrival Contact Number"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("arrivalMeetingPoint")}
          label="Arrival Meeting Point"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("aircraft")} label="Aircraft" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("registration")}
          label="Registration"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("seating")} label="Seating" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("baggage")} label="Baggage" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("captain")} label="Captain" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("flightAttendants")}
          label="Flight Attendant(s)"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("catering")} label="Catering" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("firstOfficer")}
          label="First Officer"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField {...commonTextFieldProps("newspapers")} label="Newspapers" />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("groundTransport")}
          label="Ground Transport"
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4
        }}>
        <TextField
          {...commonTextFieldProps("operationalInfo")}
          label="Operational Information"
        />
      </Grid2>
    </Grid2>)
  );
}

export default ItinerarySectionExtended;
