import React, { useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Box, useTheme, Skeleton } from "@mui/material";
import { getBookingRequestOverviewGraphData } from "../../API/Bookings";
import { useQuery } from "@tanstack/react-query";
import { PageTypography } from "../utilities/PageTitle";

function BookingsOverviewBarChart({}) {
  const theme = useTheme();

  const graphData = useQuery({
    queryKey: ["getBookingRequestOverviewGraphData"],
    queryFn: () => getBookingRequestOverviewGraphData().promise,
  });

  return (
    <Box sx={{ height: "auto", marginTop: theme.spacing(6) }}>
      {!!graphData.data ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LineChart
            height={200}
            series={[
              {
                data: graphData.data.values,
                color: theme.palette.secondary.main,
              },
            ]}
            xAxis={[{ data: graphData.data.months, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30 }}
          />
          <PageTypography
            sx={{ fontWeight: 600, paddingTop: theme.spacing(2) }}
          >
            Booking Requests / Month
          </PageTypography>
        </Box>
      ) : (
        <Skeleton variant="rounded" width="100%" height="200px" />
      )}
    </Box>
  );
}

export default BookingsOverviewBarChart;
