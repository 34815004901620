import {
  secureJsonFetch,
  securePost,
  secureBlobFetch,
} from "../utils/secureFetch";
import { JetCardApiUri } from "../constants";

//----Itineraries---------------------------------------------------------------------------------------------------------------

function getItineraryData(_itineraryId) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalFlightManagement/getItineraryData?ItineraryId=${_itineraryId}`
  );
}
function getUpdatedScheduleDetails(_scheduleId) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalItinerary/getUpdatedScheduleDetails?ScheduleId=${_scheduleId}`
  );
}

function getWalletItineraryData(_scheduleId) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalFlightManagement/getWalletItineraryData?ScheduleId=${_scheduleId}`
  );
}

// ----Initial Itinerary --------------------------------------------------------------------------------------
function getCurrentInitialItineraryValues(_scheduleId) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalItinerary/getCurrentInitialItineraryValues?ScheduleId=${_scheduleId}`
  );
}

function createInitialItinerary(_initialItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/createInitialItinerary",
    _initialItinerary
  );
}

function createAndCompleteInitialItinerary(_initialItinerary) {
  return securePost(
    JetCardApiUri +
      "api/v1.0/portalItinerary/createAndCompleteInitialItinerary",
    _initialItinerary
  );
}

function editInitialItinerary(_initialItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/editInitialItinerary",
    _initialItinerary
  );
}

function editAndCompleteInitialItinerary(_initialItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/editAndCompleteInitialItinerary",
    _initialItinerary
  );
}
// ----Final Itinerary --------------------------------------------------------------------------------------
function getCurrentFinalItineraryValues(_scheduleId) {
  return secureJsonFetch(
    JetCardApiUri +
      `api/v1.0/portalItinerary/getCurrentFinalItineraryValues?ScheduleId=${_scheduleId}`
  );
}

function createFinalItinerary(_finalItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/createFinalItinerary",
    _finalItinerary
  );
}

function createAndCompleteFinalItinerary(_finalItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/createAndCompleteFinalItinerary",
    _finalItinerary
  );
}

function editFinalItinerary(_finalItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/editFinalItinerary",
    _finalItinerary
  );
}

function editAndCompleteFinalItinerary(_finalItinerary) {
  return securePost(
    JetCardApiUri + "api/v1.0/portalItinerary/editAndCompleteFinalItinerary",
    _finalItinerary
  );
}

// ----Exporting Itinerary --------------------------------------------------------------------------------------

function exportToPdf(_itineraryId) {
  return secureBlobFetch(
    JetCardApiUri +
      `api/v1.0/portalItineraryExport/exportToPdf?ItineraryId=${_itineraryId}`
  );
}

function issueWalletItineraries(_scheduleId, _emailAdress) {
  return securePost(
    JetCardApiUri + "api/v1.0/walletItinerary/issueWalletItineraries",
    { scheduleId: _scheduleId, emailAddress: _emailAdress }
  );
}

export {
  getItineraryData,
  getUpdatedScheduleDetails,
  getWalletItineraryData,
  getCurrentInitialItineraryValues,
  createInitialItinerary,
  createAndCompleteInitialItinerary,
  editInitialItinerary,
  editAndCompleteInitialItinerary,
  getCurrentFinalItineraryValues,
  createFinalItinerary,
  createAndCompleteFinalItinerary,
  editFinalItinerary,
  editAndCompleteFinalItinerary,
  exportToPdf,
  issueWalletItineraries,
};
