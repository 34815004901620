import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    useTheme
} from '@mui/material';
import dayjs from 'dayjs';

function ViewItineraryTable({ itineraryData }) {

    const theme = useTheme();
    const style = {
        tdRight: {
            textAlign: "right",
            padding: "0.3em",
        },
        tdGeneral: {
            padding: "0.3em",
        },
    };

    return (
        <Box sx={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(3), overflow: "auto", flex: 1 }}>
            <Table padding="none">
                <TableBody>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Card Hours</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.cardHours}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Trip Date</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{dayjs(itineraryData?.tripDate).format("DD/MM/YYYY")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Departure Airport</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.departureAirport}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Departure Time</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{dayjs(itineraryData?.departureTime).format("HH:mm - DD/MM/YYYY")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Arrival Airport</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.arrivalAirport}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Arrival Time</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{dayjs(itineraryData?.arrivalTime).format("HH:mm - DD/MM/YYYY")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Flight Time</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.flightTime}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Lead Passenger</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.leadPassenger}</TableCell>
                    </TableRow>
                    {itineraryData?.passengers.map((passenger, index) =>
                        <TableRow>
                            <TableCell sx={style.tdGeneral} variant="head">
                                {index === 0 ? <b>Passenger(s)</b> : ""}
                            </TableCell>
                            <TableCell sx={style.tdRight}>{passenger}</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Departure Handling Agent</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.departureHandlingAgent}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Departure Contact Number</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.departureContactNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Departure Meeting Point</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.departureMeetingPoint}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Arrival Handling Agent</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.arrivalHandlingAgent}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Arrival Contact Number</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.arrivalContactNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Arrival Meeting Point</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.arrivalMeetingPoint}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Aircraft</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.aircraft}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Registration</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.registration}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Seating</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.seating}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Baggage</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.baggage}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Captain</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.captain}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>First Officer</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.firstOfficer}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Flight Attendant(s)</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.flightAttendants}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Catering</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.catering}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Newspapers</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.newspapers}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Ground Transport</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.groundTransport}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={style.tdGeneral} variant="head">
                            <b>Operational Information</b>
                        </TableCell>
                        <TableCell sx={style.tdRight}>{itineraryData?.operationalInfo}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

export default ViewItineraryTable;