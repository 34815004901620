import React from 'react';
import { Typography, useTheme } from '@mui/material';

function PageTitle({ children, sx, type = "large", ...props }) {
    const theme = useTheme();

    const getFontSize = () => {
        return type === 'large' ? '57px' :
            type === 'medium' ? '35px' :
                type === 'small' ? '26px' :
                    '57px';
    }

    return (
        <Typography fontWeight={475} fontSize={getFontSize()} sx={{ color: theme.palette.primaryText.main, marginBottom: theme.spacing(1), ...sx }} {...props} >
            {children}
        </Typography>
    );
};

export function PageSubTitle({ children, sx, type = "large", ...props }) {
    const theme = useTheme();

    const getFontSize = () => {
        return type === 'large' ? '24px' :
            type === 'medium' ? '20px' :
                type === 'small' ? '18px' :
                    '24px';
    }

    return (
        <Typography fontWeight={475} fontSize={getFontSize()}
            sx={{
                color: theme.palette.primaryText.main,
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(6),
                ...sx
            }} {...props} >
            {children}
        </Typography>
    );
};

export function PageTypography({ children, sx, ...props }) {
    const theme = useTheme();

    return (
        <Typography sx={{ color: theme.palette.primaryText.main, marginTop: theme.spacing(1), ...sx }} {...props} >
            {children}
        </Typography>
    );
};

export default PageTitle;