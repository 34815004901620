import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    useTheme,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HeaderBranding, { CommonHeaderIcon } from "./HeaderBranding";
import { RoleView } from "../SecureComponent";

function HeaderBar({ settings = { title: "undefined" }, navItems, handleDrawerToggle }) {
    const theme = useTheme();

    return (

        <AppBar position="static"
            sx={{
                backgroundColor: `${theme.palette.background.main}`,
                backgroundImage: "none",
                width: "100%",
                boxShadow: "none",
                display: { sm: "none" }
            }}>
            <Toolbar>
                <RoleView
                    key="roleView"
                    roles={settings.roles}
                    unAuthorised={<HeaderBranding>Mach</HeaderBranding>}
                    loadingContext={<HeaderBranding>Mach</HeaderBranding>}
                    userNotFound={<HeaderBranding>Mach</HeaderBranding>}
                >
                    <IconButton
                        onClick={handleDrawerToggle}
                        size="large"
                        edge="start"
                        color="primaryText"
                        aria-label="menu"
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <CommonHeaderIcon />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: theme.palette.primaryText.main, fontWeight: 'bold', }}
                    >
                        {settings.title}
                    </Typography>
                    {navItems}
                </RoleView>
            </Toolbar>
        </AppBar>

    );
}

export default HeaderBar;
