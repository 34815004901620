import React, { useState, useEffect, createContext, useContext } from "react";
import { useAuth } from "react-oidc-context";
import Layout from "./Layout";
import { Typography } from "@mui/material";
import CircularProgressWithIcon from "./CircularProgressWithIcon";
import { fetchUserDetails } from "../../API/Admin";
import { ROLES } from "../../constants";
import ForbiddenPage from "./ForbiddenPage";
import UserNotFoundPage from "./UserNotFoundPage";
import LoginPage from "./LoginPage";
import LoadingPage from "./LoadingPage";
import AuthenticationFailedPage from "./AuthenticationFailedPage";

const makeStyles = () => {
    return {
        content: {
            margin: "auto",
            display: "block",
            marginTop: 2,
            textAlign: "center",
        },
    };
};

const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
};

export function SecureComponent({ children, roles }) {
    const [user, setUser] = useState(undefined);
    const auth = useAuth();
    const styles = makeStyles();

    useEffect(() => {
        if (auth.isAuthenticated === true) {
            const { promise, abort } = fetchUserDetails();
            promise.then(user => {
                setUser(user)
            }).catch(error => {
                if (error === 404) {
                    setUser(404)
                }
            });
            return () => abort();
        }
    }, [auth.isAuthenticated]);

    function signInRedirect() {
        localStorage.setItem("returnUrl", window.location.href);
        auth.signinPopup();
    }

    function loadingContextRedirect() {
        return <LoadingPage />;
    }

    function userNotFoundRedirect() {
        return <UserNotFoundPage />
    }

    function forbiddenRedirect() {
        return <ForbiddenPage />;
    }

    switch (auth.activeNavigator) {
        case "signinSilent":
            return (
                <Layout>
                    <CircularProgressWithIcon />
                    <Typography sx={styles.content}>Signing you in...</Typography>
                </Layout>
            );
        case "signoutRedirect":
            return (
                <Layout>
                    <CircularProgressWithIcon />
                    <Typography sx={styles.content}>Signing you out...</Typography>
                </Layout>
            );
    }

    if (auth.isLoading) {
        return <LoadingPage />;
    }

    if (auth.error) {
        //Popup closed error handling
        if (auth.error === "Error: Popup closed by user") {
            return <LoginPage signInRedirect={signInRedirect} />;
        } else {
            return <AuthenticationFailedPage signInRedirect={signInRedirect} />;
        }
    }


    if (!auth.isAuthenticated) {
        return <LoginPage signInRedirect={signInRedirect} />;
    }

    return (
        <UserContext.Provider value={user}>
            <RoleView roles={roles} unAuthorised={forbiddenRedirect()} loadingContext={loadingContextRedirect()} userNotFound={userNotFoundRedirect()}>
                {children}
            </RoleView>
        </UserContext.Provider>
    );
}

export function getRoleViewName(roles, user, unAuthorised = null) {
    if (user === 404) return "userNotFound";
    if (!user || !roles) return "loadingContent";
    if (user.role.id === ROLES.SUPER_USER) return "children";
    if (roles.length && roles.includes(user.role.id)) return "children";
    if (unAuthorised) return "unAuthorised";
    return null;
}

export function RoleView({ roles, children, unAuthorised, loadingContext, userNotFound }) {
    const user = useUserContext();
    switch (getRoleViewName(roles, user, unAuthorised)) {
        case "userNotFound":
            return <>{userNotFound}</>;
        case "loadingContent":
            return <>{loadingContext}</>;
        case "children":
            return <>{children}</>;
        case "unAuthorised":
            return <>{unAuthorised}</>
        default:
            return null;
    }
}

export default SecureComponent;
