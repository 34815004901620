const extractTextFromJsx = (jsx) => {
  if (Array.isArray(jsx)) {
    return jsx.map(extractTextFromJsx).join("");
  } else if (typeof jsx === "string") {
    return jsx;
  } else if (jsx?.type === "br") {
    return "\n";
  } else if (typeof jsx === "object" && jsx !== null) {
    return extractTextFromJsx(jsx.props.children);
  } else {
    return "";
  }
};

const renderCellContent = (row, column) => {
  const value = column.valueGetter
    ? column.valueGetter({ row, field: column.field })
    : row[column.field];

  if (column.renderCell) {
    return column.renderCell({ row, value });
  }

  if (typeof value === "object" && value !== null) {
    console.error("Error Loading Data", JSON.stringify(value));
    return "Error Loading Data";
  }

  return value;
};

const distinctValues = (field, rows, columns, searchTerm = "") => {
  const column = columns.find((col) => col.field === field);

  const values = Array.from(
    new Set(
      rows.map((row) => {
        const value = column.valueGetter
          ? column.valueGetter({ row, field })
          : row[field];
        return value;
      })
    )
  )
    .filter((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort();

  return values;
};

const sortRows = (rows, config, filters, columns) => {
  const { field, direction } = config;
  if (!field) return rows;

  let filteredRows = rows;

  Object.keys(filters).forEach((colField) => {
    const selectedValues = filters[colField];
    if (selectedValues.length > 0) {
      const column = columns.find((col) => col.field === colField);
      filteredRows = filteredRows.filter((row) => {
        const value = column.valueGetter
          ? column.valueGetter({ row, field: colField })
          : row[colField];
        return selectedValues.includes(value);
      });
    }
  });

  const sortedRows = [...filteredRows].sort((a, b) => {
    const column = columns.find((col) => col.field === field);
    const aValue = column.valueGetter
      ? column.valueGetter({ row: a, field })
      : a[field];
    const bValue = column.valueGetter
      ? column.valueGetter({ row: b, field })
      : b[field];

    const comparator = column?.sortComparator;

    let comparison = 0;

    if (comparator) {
      comparison = comparator(aValue, bValue);
    } else {
      if (aValue < bValue) {
        comparison = -1;
      } else if (aValue > bValue) {
        comparison = 1;
      }
    }

    return direction === "asc" ? comparison : -comparison;
  });

  return sortedRows;
};

export { extractTextFromJsx, renderCellContent, distinctValues, sortRows };
