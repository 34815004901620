import React from "react";
import { getPortalUsers } from "../../API/Admin";
import { useQuery } from "@tanstack/react-query";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import MachDataTable from "../utilities/MachDataTable/MachDataTable";

function UserTable({ setSelectedUserRow, selectedUserRow, toolbarButtons }) {
  const rows = useQuery({
    queryKey: ["getPortalUsers"],
    queryFn: () => getPortalUsers().promise,
  });

  const columns = [
    { field: "email", headerName: "E-Mail" },
    {
      field: "role",
      headerName: "Role",
      valueGetter: (params) => params?.row?.role?.name,
    },
    {
      field: "isActive",
      headerName: "Is Active?",
      valueGetter: (params) => (params?.row?.isActive ? "Yes" : "No"),
    },
  ];

  return (
    <QueryHandlerContainer query={rows} width="100%" height="75vh">
      <MachDataTable
        rows={rows?.data}
        columns={columns}
        tableName="MachUserManage"
        defaultSortField="email"
        onRowClick={(user) => {
          if (user !== selectedUserRow) {
            setSelectedUserRow(user);
          } else {
            setSelectedUserRow(null);
          }
        }}
        selected={selectedUserRow}
        toolbarButtons={toolbarButtons}
      />
    </QueryHandlerContainer>
  );
}

export default UserTable;
