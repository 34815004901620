import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  useTheme,
  Divider,
  Button,
  Tooltip,
  Box,
} from "@mui/material";
import { getFlightManagementItineraries } from "../../API/FlightManagement";
import { useQuery } from "@tanstack/react-query";
import { PageSubTitle } from "../utilities/PageTitle";
import ReorderIcon from "@mui/icons-material/Reorder";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import InitialItineraryModal from "../ItineraryConstruction/InitialItineraryModal";
import ViewItineraryPanel from "../ItineraryConstruction/ViewItineraryPanel";
import { routes } from "../../AppRoutes";
import { useNavigate } from "react-router-dom";
import ManageItineraryActionsSection from "./ManageItineraryActionsSection";
import WalletItineraryActionsSection from "./WalletItineraryActionsSection";

function ItineraryDetailsWidget({ scheduleId }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [initialItineraryModalOpen, setInitialItineraryModalOpen] =
    useState(false);
  const [viewItineraryPanelOpen, setViewItineraryPanelOpen] = useState(false);
  const [viewingItineraryId, setViewingItineraryId] = useState(null);

  const flightManagementItinerariesQuery = useQuery({
    queryKey: ["getFlightManagementItineraries", scheduleId],
    queryFn: () => getFlightManagementItineraries(scheduleId).promise,
  });

  const openViewItineraryPanel = (itineraryId) => {
    setViewingItineraryId(itineraryId);
    setViewItineraryPanelOpen(true);
  };

  const closeViewItineraryPanel = () => {
    setViewItineraryPanelOpen(false);
    setViewingItineraryId(null);
  };

  const flightManagementItineraries = flightManagementItinerariesQuery?.data;

  const handleFinalItineraryCreateEditClick = () => {
    const route = routes
      .finalItinerary()
      .generatePath(scheduleId, flightManagementItineraries?.finalItineraryId);
    navigate(route);
  };

  return (
    <QueryHandlerContainer
      height="400px"
      width="100%"
      query={flightManagementItinerariesQuery}
    >
      <Card
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor: theme.palette.backgroundContrast.secondary,
          border: "none",
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={<ReorderIcon sx={{ fontSize: "2em" }} />}
          title={
            <PageSubTitle
              variant="subtitle1"
              sx={{ marginTop: theme.spacing(1), marginBottom: 0 }}
            >
              Itineraries
            </PageSubTitle>
          }
        />
        <Divider />
        <ManageItineraryActionsSection
          itineraryId={flightManagementItineraries?.initialItineraryId}
          itineraryStatus={flightManagementItineraries?.initialItineraryStatus}
          handleItineraryCreateEditClick={() =>
            setInitialItineraryModalOpen(true)
          }
          openViewItineraryPanel={openViewItineraryPanel}
          title="Initial Itinerary"
          disableModify={
            flightManagementItineraries?.isInitialItineraryDisabled
          }
        />
        <Divider />
        <ManageItineraryActionsSection
          itineraryId={flightManagementItineraries?.finalItineraryId}
          itineraryStatus={flightManagementItineraries?.finalItineraryStatus}
          handleItineraryCreateEditClick={handleFinalItineraryCreateEditClick}
          openViewItineraryPanel={openViewItineraryPanel}
          title="Final Itinerary"
        />
        <Divider />
        {flightManagementItineraries?.canIssueWalletItineraries ? (
          <WalletItineraryActionsSection scheduleId={scheduleId} />
        ) : (
          <></>
        )}
      </Card>
      <ViewItineraryPanel
        open={viewItineraryPanelOpen}
        handleClose={closeViewItineraryPanel}
        itineraryId={viewingItineraryId}
      />
      <InitialItineraryModal
        open={initialItineraryModalOpen}
        setOpen={setInitialItineraryModalOpen}
        scheduleId={scheduleId}
        initialItineraryId={flightManagementItineraries?.initialItineraryId}
      />
    </QueryHandlerContainer>
  );
}

export default ItineraryDetailsWidget;
