import React from "react";
import { TextField, useTheme } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

function AddPassengerInput({
  options = [],
  selectedPassenger,
  setSelectedPassenger,
  disabled,
}) {
  const theme = useTheme();

  const handleChange = (event, newValue, changeType) => {
    setSelectedPassenger((previousValue) => {
      const updatedValue = { ...previousValue };
      if (newValue?.id) {
        updatedValue.firstName = newValue.firstName;
        updatedValue.lastName = newValue.lastName;
        updatedValue.id = newValue.id;
      } else {
        updatedValue[changeType] = event?.target?.value ?? "";
        updatedValue.id = "";
      }
      return updatedValue;
    });
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    return filtered.filter(
      (option) =>
        option.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.lastName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const renderOption = (props, option) => {
    if (!!option?.firstName && !!option?.lastName) {
      return (
        <li {...props} key={option?.id}>
          {option.firstName} {option.lastName}
        </li>
      );
    }
    return "";
  };

  return (
    <>
      <Autocomplete
        freeSolo
        value={selectedPassenger}
        onChange={(event, newValue) =>
          handleChange(event, newValue, "firstName")
        }
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        isOptionEqualToValue={(option, value) => option.id === value}
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => option?.firstName ?? option}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params} label="First Name" />}
        disabled={disabled}
      />
      <Autocomplete
        sx={{ marginTop: theme.spacing(3) }}
        freeSolo
        value={selectedPassenger}
        onChange={(event, newValue) =>
          handleChange(event, newValue, "lastName")
        }
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => option?.lastName ?? option}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params} label="Last Name" />}
        disabled={disabled}
      />
    </>
  );
}

export default AddPassengerInput;
