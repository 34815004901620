import React from "react";
import Layout from "./Layout";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import CenterComponent from "../utilities/CenterContent";

function ForbiddenPage() {

    return (
        <Layout>
            <CenterComponent>
                <PageTitle>
                    Not Authorised
                </PageTitle>
                <PageTypography>The Page you're requesting is not available to you.</PageTypography>
                <PageTypography>Please check your URL, or contact your manager if you need your access amending.</PageTypography>
            </CenterComponent>
        </Layout>
    );
}

export default ForbiddenPage;