import React from 'react';
import AirPartnerIcon from '../Icons/AirPartnerIcon';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

export const CommonHeaderIcon = () => {
    const theme = useTheme();

    return (
        <AirPartnerIcon
            fontSize="large"
            sx={{
                marginRight: theme.spacing(5),
            }}
        />
    );
}


function HeaderBranding({ typographySx, typographyProps, children }) {
    const theme = useTheme();

    return (
        <>
            <CommonHeaderIcon />
            <Typography
                {...typographyProps}
                variant="h6"
                component="div"
                sx={{ mr: "auto", color: theme.palette.primaryText.main, fontWeight: 'bold', paddingRight: theme.spacing(5.25), ...typographySx }}
            >
                {children}
            </Typography>
        </>
    );
};

export default HeaderBranding;