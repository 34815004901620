import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ItinerarySectionInitial from "../ItineraryConstruction/ItinerarySectionInitial";
import { useTheme, Divider, Box, Button } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  mapFinalItineraryDtoToFormValues,
  mapFormValuesToFinalItineraryDto,
  mapUpdatedItineraryDtoToFormValues,
} from "../ItineraryConstruction/ItineraryHelpers";
import { PageTypography } from "../utilities/PageTitle";
import { JetCardTechSupportContact } from "../../constants";
import {
  createFinalItinerary,
  editFinalItinerary,
  createAndCompleteFinalItinerary,
  editAndCompleteFinalItinerary,
  getUpdatedScheduleDetails,
} from "../../API/Itineraries";
import ItinerarySectionExtended from "../ItineraryConstruction/ItinerarySectionExtended";
import { useQueryClient } from "@tanstack/react-query";
import { routes } from "../../AppRoutes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

function FinalItineraryPageContent({ values, scheduleId, isNewItinerary }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveAndCompleteLoading, setSaveAndCompleteLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [passengers, setPassengers] = useState(values.passengers);
  const queryClient = useQueryClient();

  const isLoading = saveLoading || saveAndCompleteLoading || refreshLoading;
  const defaultValues = { ...mapFinalItineraryDtoToFormValues(values) };
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const navigateToFlightManagement = () => {
    const route = routes.flightManagement().generatePath(scheduleId);
    navigate(route);
  };

  const handleSave = (data) => {
    setSaveLoading(true);
    const itineraryApiCall = isNewItinerary
      ? createFinalItinerary
      : editFinalItinerary;
    const { promise, abort } = itineraryApiCall(
      mapFormValuesToFinalItineraryDto(scheduleId, data, passengers)
    );
    promise.then(() => {
      setApiError(false);
      setSaveLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["getFlightManagementItineraries", scheduleId],
      });
      navigateToFlightManagement();
    });
    promise.catch(() => {
      setApiError(true);
      setSaveLoading(false);
    });
    return () => abort();
  };

  const handleSaveAndComplete = (data) => {
    setSaveAndCompleteLoading(true);
    const itineraryApiCall = isNewItinerary
      ? createAndCompleteFinalItinerary
      : editAndCompleteFinalItinerary;
    const { promise, abort } = itineraryApiCall(
      mapFormValuesToFinalItineraryDto(scheduleId, data, passengers)
    );
    promise.then(() => {
      setApiError(false);
      setSaveAndCompleteLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["getFlightManagementItineraries", scheduleId],
      });
      navigateToFlightManagement();
    });
    promise.catch(() => {
      setApiError(true);
      setSaveAndCompleteLoading(false);
    });
    return () => abort();
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    const { promise, abort } = getUpdatedScheduleDetails(scheduleId);
    promise.then((data) => {
      const newFormValues = mapUpdatedItineraryDtoToFormValues(data);
      const oldFormValues = getValues();
      setPassengers(data.passengers);
      reset({ ...oldFormValues, ...newFormValues });
      setApiError(false);
      setRefreshLoading(false);
    });
    promise.catch(() => {
      setApiError(true);
      setRefreshLoading(false);
    });
    return () => abort();
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginBottom: theme.spacing(3) }}
          startIcon={<ArrowBackIcon />}
          onClick={navigateToFlightManagement}
        >
          Back
        </Button>
        <LoadingButton
          color="secondary"
          variant="outlined"
          onClick={handleRefresh}
          sx={{ marginBottom: theme.spacing(3) }}
          endIcon={<PublicIcon />}
          loading={refreshLoading}
          disabled={isLoading}
          loadingPosition="end"
        >
          Refresh
        </LoadingButton>
      </Box>
      <ItinerarySectionInitial
        control={control}
        register={register}
        passengers={passengers}
        getValues={getValues}
        errors={errors}
        isLoading={isLoading}
      />
      <Divider
        sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <ItinerarySectionExtended
        register={register}
        isLoading={isLoading}
        errors={errors}
      />
      {apiError && (
        <Box sx={{ marginTop: theme.spacing(3) }}>
          <PageTypography
            fontWeight="bold"
            sx={{ color: theme.palette.error.main }}
          >
            Error, Please try again.
          </PageTypography>
          <PageTypography sx={{ color: theme.palette.error.main }}>
            If this continues, contact our Support Team:{" "}
            {JetCardTechSupportContact}
          </PageTypography>
        </Box>
      )}
      <Divider
        sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <LoadingButton
          variant="outlined"
          onClick={() => handleSave(getValues())}
          startIcon={<SaveIcon />}
          sx={{ marginLeft: "auto !important" }}
          disabled={isLoading}
          loading={saveLoading}
          loadingPosition="start"
          color="secondary"
        >
          Save
        </LoadingButton>
        <LoadingButton
          sx={{ marginLeft: theme.spacing(3) }}
          variant="contained"
          onClick={handleSubmit(handleSaveAndComplete)}
          endIcon={<DoneAllIcon />}
          loading={saveAndCompleteLoading}
          disabled={isLoading}
          loadingPosition="end"
          color="secondary"
        >
          Save & Complete
        </LoadingButton>
      </Box>
    </>
  );
}

export default FinalItineraryPageContent;
