import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  useTheme,
  Divider,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { PageSubTitle } from "../utilities/PageTitle";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import JourneyLegInput from "./JourneyLegInput";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import dayjs from "dayjs";

function JourneyLegsWidget({ getValues, control, disabled, setValue }) {
  const theme = useTheme();
  const journeyLegs = getValues().journeyLegs;
  const [isReturn, setIsReturn] = useState(false);

  const handleAddReturn = () => {
    setIsReturn(true);
    setValue(`journeyLegs[1]`, {
      startAirport: journeyLegs[0].endAirport,
      endAirport: journeyLegs[0].startAirport,
      departureDateTime: null,
    });
  };
  const handleAddLeg = () => {
    setValue(`journeyLegs[${journeyLegs.length}]`, {
      startAirport: journeyLegs[journeyLegs.length - 1].endAirport,
      endAirport: null,
      departureDateTime: null,
    });
  };

  const addLegDisabled =
    journeyLegs[journeyLegs.length - 1]?.endAirport == null ||
    journeyLegs[journeyLegs.length - 1]?.departureDateTime == null ||
    isReturn;
  const addReturnDisabled =
    journeyLegs[0]?.endAirport == null ||
    journeyLegs[0]?.startAirport == null ||
    journeyLegs[0]?.departureDateTime == null ||
    journeyLegs[1] != undefined;

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "5px",
        backgroundColor: theme.palette.backgroundContrast.secondary,
        border: "none",
      }}
      variant="outlined"
    >
      <CardHeader
        avatar={<ConnectingAirportsIcon sx={{ fontSize: "2em" }} />}
        title={
          <PageSubTitle
            variant="subtitle1"
            sx={{ marginTop: theme.spacing(1), marginBottom: 0 }}
          >
            Journey Legs
          </PageSubTitle>
        }
      />
      <Divider />

      {journeyLegs.map((leg, index) => (
        <Box key={index}>
          {index != 0 && <Divider />}
          <CardContent>
            <Controller
              name={`journeyLegs[${index}]`}
              sx={{ width: "100%" }}
              control={control}
              rules={{ required: "This is Required" }}
              render={({ field }) => (
                <JourneyLegInput
                  index={index}
                  disabled={journeyLegs.length != index + 1 || disabled}
                  value={field.value}
                  onChange={field.onChange}
                  isReturn={index != 0 && isReturn}
                  isLast={journeyLegs.length - 1 == index}
                  minimumDate={
                    index != 0
                      ? journeyLegs[journeyLegs.length - 2].departureDateTime
                      : dayjs()
                  }
                  removeLeg={() => {
                    setIsReturn(false);
                    setValue(
                      `journeyLegs`,
                      getValues().journeyLegs.filter(
                        (value, index) => index != journeyLegs.length - 1
                      )
                    );
                  }}
                />
              )}
            />
          </CardContent>
        </Box>
      ))}

      <Divider />
      <CardActions sx={{ display: "flex" }}>
        <Button
          variant="outlined"
          onClick={handleAddLeg}
          endIcon={<AddIcon />}
          disabled={addLegDisabled}
          fullWidth
        >
          Add Leg
        </Button>
        <Button
          endIcon={<KeyboardReturnIcon />}
          onClick={handleAddReturn}
          disabled={addReturnDisabled}
          variant="outlined"
          fullWidth
        >
          Add Return
        </Button>
      </CardActions>
    </Card>
  );
}

export default JourneyLegsWidget;
