import React, { useEffect } from "react";
import Layout from "../common/Layout";
import CenterComponent from "../utilities/CenterContent";
import { useParams, useNavigate } from "react-router-dom";
import { IsValidGuid } from "../../utils/tools";
import {
  getUpdatedScheduleDetails,
  getCurrentFinalItineraryValues,
} from "../../API/Itineraries";
import { useQuery } from "@tanstack/react-query";
import QueryHandlerContainer from "../utilities/QueryHandlerContainer";
import FinalItineraryPageContent from "./FinalItineraryPageContent";

function FinalItineraryPage({}) {
  const { scheduleId, itineraryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!scheduleId && !IsValidGuid(scheduleId)) {
      navigate("/notFound");
    }
  }, [scheduleId]);

  const isNewItinerary = !itineraryId || !IsValidGuid(itineraryId);

  const getUpdatedScheduleDetailsQuery = useQuery({
    queryKey: ["getUpdatedScheduleDetails", scheduleId],
    queryFn: () => getUpdatedScheduleDetails(scheduleId).promise,
    enabled: isNewItinerary,
  });

  const getCurrentFinalItineraryValuesQuery = useQuery({
    queryKey: ["getCurrentFinalItineraryValues", scheduleId],
    queryFn: () => getCurrentFinalItineraryValues(scheduleId).promise,
    enabled: !isNewItinerary,
  });

  const itineraryData = isNewItinerary
    ? getUpdatedScheduleDetailsQuery.data
    : getCurrentFinalItineraryValuesQuery.data;

  return (
    <Layout>
      <CenterComponent>
        <QueryHandlerContainer
          width="100%"
          height="75vh"
          query={
            isNewItinerary
              ? getUpdatedScheduleDetailsQuery
              : getCurrentFinalItineraryValuesQuery
          }
        >
          <FinalItineraryPageContent
            values={itineraryData}
            scheduleId={scheduleId}
            isNewItinerary={isNewItinerary}
          />
        </QueryHandlerContainer>
      </CenterComponent>
    </Layout>
  );
}

export default FinalItineraryPage;
