import React from "react";
import {
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function MachDataTableHeaders({
  columns,
  sortConfig,
  handleSort,
  handleFilterButtonClick,
}) {
  const theme = useTheme();
  return (
    <TableRow>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          sx={{
            minWidth: column.minWidth || "auto",
            top: "31.75px",
            backgroundColor: theme.palette.background.main,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TableSortLabel
              active={sortConfig.field === column.field}
              direction={
                sortConfig.field === column.field ? sortConfig.direction : "asc"
              }
              onClick={() => handleSort(column.field)}
            >
              {column.headerName}
            </TableSortLabel>
            {!column.hideFilter && (
              <IconButton
                size="small"
                onClick={(event) => handleFilterButtonClick(event, column)}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
}

export default MachDataTableHeaders;
