import React, { useState } from "react";
import {
  Drawer,
  Box,
  useTheme,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import PageTitle, { PageTypography } from "../utilities/PageTitle";
import CircularProgressWithIcon from "../common/CircularProgressWithIcon";
import { useQuery } from "@tanstack/react-query";
import { getBookingRequestSummaryDetails } from "../../API/Bookings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import JourneyLegDisplay from "./JourneyLegDisplay";
import { routes } from "../../AppRoutes";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TitleCell = ({ title }) => {
  const theme = useTheme();
  return (
    <TableCell sx={{ padding: theme.spacing(1) }} variant="head">
      <b>{title}</b>
    </TableCell>
  );
};

const ContentCell = ({ children }) => {
  const theme = useTheme();
  return (
    <TableCell sx={{ textAlign: "right", padding: theme.spacing(1) }}>
      {children}
    </TableCell>
  );
};

function MoreDetailsPanel({ open, closeMoreDetailsPanel, selectedRowId }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleFlightClick = (_scheduleId) => {
    const route = routes.flightManagement().generatePath(_scheduleId);
    navigate(route);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const details = useQuery({
    queryKey: ["getBookingRequestSummaryDetails", selectedRowId],
    queryFn: () => getBookingRequestSummaryDetails(selectedRowId).promise,
    enabled: !!selectedRowId,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => closeMoreDetailsPanel()}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "400px" },
        },
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageTitle type="medium" sx={{ color: theme.palette.secondary.main }}>
          Details
        </PageTitle>
        <PageTypography
          sx={{
            color: theme.palette.primaryText.main,
            marginBottom: theme.spacing(6),
          }}
        >
          View more details about this request.
        </PageTypography>
        {!!details.isFetched ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Table padding="none">
              <TableBody>
                <TableRow>
                  <TitleCell title="Customer" />
                  <ContentCell>{details.data.customerName}</ContentCell>
                </TableRow>
                <TableRow>
                  <TitleCell title="JetCard" />
                  <ContentCell>{details.data.jetCard}</ContentCell>
                </TableRow>
                <TableRow>
                  <TitleCell title="Booking Status" />
                  <ContentCell>{details.data.bookingStatus}</ContentCell>
                </TableRow>
                {details.data.legSummaries.map((leg) => (
                  <TableRow key={leg.scheduleId}>
                    <TableCell colSpan={2} sx={{ padding: theme.spacing(1) }}>
                      <Tooltip
                        title="Click for Flight Info"
                        placement="left"
                        arrow
                      >
                        <Button
                          sx={{ textTransform: "none", textAlign: "left" }}
                          onClick={() => {
                            handleFlightClick(leg.scheduleId);
                          }}
                        >
                          <JourneyLegDisplay leg={leg} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {!!details.data.description && (
                  <TableRow>
                    <TitleCell title="More Information" />
                    <ContentCell>
                      <ExpandMore expand={expanded} onClick={handleExpandClick}>
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </ContentCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Collapse in={expanded} unmountOnExit>
              <Box sx={{ padding: theme.spacing(1) }}>
                {details.data.description}
              </Box>
            </Collapse>
          </Box>
        ) : (
          <CircularProgressWithIcon />
        )}

        <Box sx={{ justifyContent: "end", display: "flex", marginTop: "auto" }}>
          <Button
            variant="contained"
            onClick={() => {
              closeMoreDetailsPanel();
            }}
            sx={{ margin: theme.spacing(1), minWidth: "100px" }}
            color="secondary"
            endIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default MoreDetailsPanel;
